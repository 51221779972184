import React, {useCallback, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    doSeatGuests,
    doSetHoveringTable,
    doSetHoveringTableOverride,
    doSetStopHoveringTable,
    selectHoveringTableId,
    selectHoveringTableOverride
} from "../../slice/tableSlice";
import Dragable from "../dragable-handle/Dragable";
import './seating-table.css'
import {doSetDraggingData, selectDraggingData} from "../../slice/seatingSlice";
import {selectCountByGuestIds} from "../../slice/guestSlice";

export default function SeatingTable({table, room, onClick}) {
    const dispatch = useDispatch()
    const hoveringTableId = useSelector(selectHoveringTableId)

    const [dragHovering, setDragHovering] = useState('');
    const hoveringTableOverride = useSelector(selectHoveringTableOverride);

    const draggingData = useSelector(selectDraggingData);

    const tableCount = useMemo(() => {
        return Object.values(table.seats).reduce((acc, c) => acc + c, 0)
    }, [table.seats])

    const onDrop = useCallback(e => {
        e.preventDefault();
        const data = e.dataTransfer.getData('application/seating')
        const {guest_ids, count} = JSON.parse(data)
        const openSeats = table.active_seats - tableCount

        if (openSeats >= count) {
            dispatch(doSeatGuests({table_id: table.id, guest_ids}))
        }
        dispatch(doSetDraggingData(null))
    }, [dispatch, table, tableCount]);

    const onDragOver = useCallback(e => {
        e.preventDefault()
        const openSeats = table.active_seats - tableCount

        const {guest_ids, count_pr_guest} = draggingData
        const total__count = count_pr_guest.reduce((acc, c) => acc + c, 0)

        let override = {}
        guest_ids.forEach((g, i) => {
            override[table.id] = {[g]: count_pr_guest[i]}
        })

        dispatch(doSetHoveringTable(table.id))
        dispatch(doSetHoveringTableOverride(override))
        if (openSeats < total__count) {
            e.dataTransfer.dropEffect = 'none'
            setDragHovering('none')
        } else {
            e.dataTransfer.dropEffect = 'link'
            setDragHovering('link')
        }
    }, [dispatch, draggingData, table, tableCount]);

    const onDragLeave = useCallback(table_id => {
        setDragHovering('')
        dispatch(doSetStopHoveringTable(table_id))
        dispatch(doSetHoveringTableOverride({}))
    }, [dispatch])

    const hovering = hoveringTableId === table.id

    const openSeats = useMemo(() => {
        if (!!hoveringTableOverride[table.id]) {
            const planned = Object.values(hoveringTableOverride[table.id]).reduce((acc, c) => acc + c, 0)
            return table.active_seats - tableCount - planned
        }
        return table.active_seats - tableCount
    }, [table, tableCount, hoveringTableOverride]);

    return (<Dragable
        onDrop={onDrop}
        onDragOver={onDragOver}

        pointer_events={true}

        // onMouseEnter={_ => dispatch(doSetHoveringTable(table.id))}
        onMouseLeave={_ => onDragLeave(table.id)}
        onDragLeave={_ => onDragLeave(table.id)}

        onClick={onClick ?? (_ => {})}

        element_id={table.id}
        key={table.id}
        className={`
            seating-table 
            ${table.type} 
            ${table.dirty && 'dirty'} 
            ${hovering ? `hovering-${dragHovering}` : ''} 
            ${table.id === -1 && 'being-placed'}
            ${openSeats === table.active_seats && 'none-seated'}
            ${openSeats < table.active_seats && openSeats > 0 && 'half-seated'}
            ${openSeats <= 0 && 'completely-seated'}
        `}

        position={table.position}
        rotation={table.rotation}
        style={{
            width: `${table.width * room.pixel_pr_meter}px`,
            height: `${table.length * room.pixel_pr_meter}px`,
            transform: `translateX(-50%) translateY(-50%) rotate(${table.rotation}deg)`,
        }}>
        <span className={`table-name`}
              onMouseDown={e => e.stopPropagation()}
              style={{
                  left: table.type === 'Square' ? `${table.width * room.pixel_pr_meter-2}px` : `${table.width * room.pixel_pr_meter * 0.5}px`,
        }}>
            {table.name}
        </span>
        <p className='seats-left-label'>
            {openSeats}
        </p>
    </Dragable>)
}
