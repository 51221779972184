import React, {useCallback, useEffect, useMemo, useState} from 'react'
import './navigatable.css'
import {renderer} from '../renderer/renderer';
import {useDispatch, useSelector} from "react-redux";
import {selectDraggingData} from "../../slice/seatingSlice";
import {selectDraggingTableId} from "../../slice/tableSlice";
import {selectNaturalImageSize} from "../../slice/roomSlice";

export default function PanZoomHandle({children}) {
    const dispatch = useDispatch();

    const [container, setContainer] = useState();
    const [appliedStartOffset, setAppliedStartOffset] = useState(false);

    const [panning, setPanning] = useState(false);
    const [holdingCtrl, setHoldingCtrl] = useState(false);

    const draggingData = useSelector(selectDraggingData);
    const draggingTableId = useSelector(selectDraggingTableId);

    const naturalImageSize = useSelector(selectNaturalImageSize);

    const instance = useMemo(() => {
        if (!container) return null;
        const containerSize = container.getBoundingClientRect();
        console.log('Initializing renderer')
        console.log({container, containerSize})
        return renderer({scaleSensitivity: 1000, minScale: .2, maxScale: 10, element: container.children[0]});
    }, [container]);

    const mouseMove = useCallback(event => {
        if (draggingTableId !== null) return;
        if (event.ctrlKey || event.buttons === 4) {
            setHoldingCtrl(true);
        } else {
            setHoldingCtrl(false);
            return;
        }
        if (!panning) return;

        const isMoving = event.buttons === 4 || (event.buttons === 1 && event.ctrlKey);

        if (!isMoving) return;
        if (!instance) return;

        if (draggingData) {
            const diff = event.timeStamp - draggingData.timestamp
            if (diff < 100) return;
        }

        event.preventDefault();
        event.stopPropagation();

        instance.panBy({
            originX: event.movementX,
            originY: event.movementY
        });
    }, [instance, draggingData, draggingTableId, panning]);

    const onWheel = useCallback((event) => {
        event.stopPropagation();
        if (!instance) return;

        instance.zoom({
            deltaScale: -event.deltaY,
            x: event.pageX,
            y: event.pageY,
            dispatch
        });
    }, [instance, dispatch]);

    useEffect(() => {
        if (!instance || !naturalImageSize || appliedStartOffset) return;
        setAppliedStartOffset(true);

        const containerSize = container.getBoundingClientRect();
        const windowSize = {
            width: window.innerWidth - containerSize.x,
        }
        console.log('setting start offset')
        console.log('containerSize: ', {
            containerSize,
            naturalImageSize,
            windowSize
        })
        // pan to (0, 0)
        instance.panBy({
            originX: windowSize.width/2,
            originY: containerSize.height/2
        })
        // // zoom out using center of screen
        const scale = 1-(window.innerWidth/containerSize.width)/1.5
        console.log('ZOOM', scale)
        instance.zoom({
            x: (window.innerWidth)/2,
            y: (window.innerHeight)/2,
            deltaScale: -Math.floor(scale*1000),
            dispatch
        });
        // // // pan image to center of screen
        const newScale = instance.state.transformation.scale
        console.log('ORIG', window.innerHeight*newScale/2 + 60)
        instance.panBy({
            originX: -containerSize.width*newScale/2,
            originY: -window.innerHeight/2+120,
        });
    }, [instance, container, naturalImageSize]);

    return (<div className={`navigatable ${panning ? 'panning' : ''} ${holdingCtrl ? 'mouse-grab' : ''}`}
                 ref={setContainer}
                 onMouseMove={mouseMove}
                 onMouseDown={e => {
                     if ((e.ctrlKey && e.buttons === 1) || e.buttons === 4) setPanning(true)
                 }}
                 onMouseUp={_ => setPanning(false)}
                 onWheel={onWheel}>
        {children}
        {holdingCtrl && <div className='mouse-grabbing'>
            <div>Click and drag to pan</div>
            <div>Scroll to zoom</div>
        </div>}
    </div>)
}
