import React, {useMemo} from 'react'
import {VectorOperations} from "../VectorOperations";
import {Vector} from "vector2d";


export default function Line({start, end, color, ...props}) {
    const center = useMemo(() => {
        if (!start || !end) return new Vector(0, 0);

        return VectorOperations.moveTowardsPercentage(start, end, 50)
    }, [start, end]);

    const angle = useMemo(() => {
        if (!start || !end) return new Vector(0, 0);

        return VectorOperations.angle(start.clone().subtract(end))
    }, [start, end]);

    const length = useMemo(() => {
        if (!start || !end) return new Vector(0, 0);

        return start.clone().subtract(end).length()
    }, [start, end]);

    return (<div className={'line'} style={{
        position: 'absolute',
        left: `${center.x - 0.5}px`,
        top: `${center.y - 0.5}px`,
        height: '1px',
        background: color || 'black',
        width: `${length}px`,
        transform: `translateX(-50%) translateY(-50%) rotate(${angle}rad)`,
        pointerEvents: 'none'
    }} {...props}/>)
}
