import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    doFetchEvent,
    selectEventById,
    saveLocallyUpdatedEventToBackend,
    selectLocallyUpdatedEvent,
    updateLocalEvent,
    selectLocallyUpdatedIsDirty, doDeleteEvent,
} from "../../../slice/eventSlice";
import {doGetUser} from "../../../slice/userSlice";
import {doFetchEventRoom, selectActiveRoom} from "../../../slice/roomSlice";
import './event-settings.css';
import InputField from "../../../component/input-field/InputField";
import {toDanishDate} from "../../../component/date_functions";
import RoomList from "../../../component/room-list/RoomList";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {EventApi} from "../../../api";
import {doDeleteAllEventGuests} from "../../../slice/guestSlice";

export default function EventSettings() {
    const {event_id, venue_id} = useParams()

    const currentEvent = useSelector(selectEventById(event_id))
    const updatedEvent = useSelector(selectLocallyUpdatedEvent)
    const updatedEventIsDirty = useSelector(selectLocallyUpdatedIsDirty)
    const room = useSelector(selectActiveRoom)

    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doFetchEvent(event_id));
        dispatch(doFetchEventRoom(event_id));
    }, [dispatch, event_id]);

    const editLocalEvent = useCallback((key, newValue) => {
        dispatch(updateLocalEvent({event_id: currentEvent.id, values: {[key]: newValue}}))
    }, []);

    const saveEvent = useCallback(() => {
        dispatch(saveLocallyUpdatedEventToBackend)
    }, [dispatch, currentEvent, updatedEvent]);

    if (!currentEvent || !updatedEvent) {
        return <div>Loading...</div>
    }

    return (<div className={'event-settings'}>
        <h2>Edit event</h2>
        <div className='event-input-fields'>
            <InputField title='Title' value={updatedEvent.title} onChanged={v => editLocalEvent('title', v)}/>
            <InputField title='Date' type='date' value={toDanishDate(updatedEvent.date)}
                        onChanged={v => editLocalEvent('date', v)}/>
            <InputField title='Contact name' value={updatedEvent.contact_name}
                        onChanged={v => editLocalEvent('contact_name', v)}/>
            <InputField title='Contact email' value={updatedEvent.contact_email}
                        onChanged={v => editLocalEvent('contact_email', v)}/>
            <InputField title='Contact phone' value={updatedEvent.contact_phone}
                        onChanged={v => editLocalEvent('contact_phone', v)}/>
            <InputField title='Expected guests' value={updatedEvent.expected_guests}
                        onChanged={v => editLocalEvent('expected_guests', v)}/>

            <SimpleButton value='Save'
                          onClick={_ => dispatch(saveLocallyUpdatedEventToBackend)}
                          disabled={!updatedEventIsDirty}
            />
        </div>
        {room && <div className='rooms'>
            <h2>Lokaler</h2>
            <RoomList rooms={[room]}/>
        </div>}

        <hr/>

        <div className='danger-zone'>
            <h1>Danger zone</h1>
            <b>
                Pas på hernede. De følgende knapper kan bruges til at slette hele events eller alle gæster på eventet.
                Det kan ikke fortrydes.
            </b>

            <SimpleButton dangerous value='Slet event' onClick={_ => {
                if (window.confirm('Er du sikker på du vil slette event, seating, bordopsætning og gæster?')) {
                    dispatch(doDeleteEvent(parseInt(event_id)))
                    navigate(`/app/venue/${venue_id}/events`)
                }
            }}/>

            {currentEvent && <SimpleButton dangerous value='Slet alle gæster på event' onClick={_ => {
                if (!window.confirm(`Slet alle gæster for ${currentEvent.title}?`)) return;
                dispatch(doDeleteAllEventGuests(currentEvent.id))
            }}/>}
        </div>
    </div>)
}
