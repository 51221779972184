import React from 'react'
import './link-button.css'
import arrDownWhite from '../../assets/arr_down_white.svg';

export default function LinkButton({className, children, href, disabled = false, loading = false, big = false}) {
    return <a
        href={href}
        className={`link-button ${className} ${disabled ? 'disabled' : ''} ${loading && 'loading'} ${big ? 'big' : ''}`}>
        {children}
        <img src={arrDownWhite} alt='loading' className='loading-icon'/>
    </a>
}
