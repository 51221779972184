import React, {useMemo, useState} from 'react';
import './calendar.css';
import {useNavigate} from "react-router-dom";

export default function Calendar({venue, events}) {

    const navigate = useNavigate();

    const eventsByDate = useMemo(() => {
        const res = {}
        events.forEach(e => {
            const d = new Date(e.date)
            if (res[d] === undefined) {
                res[d] = [];
            }
            res[d].push(e)
        })
        return res
    }, [events]);

    const [currentDate, setCurrentDate] = useState(new Date());

    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    // Adjust the start of the calendar to the Monday before the first day of the month
    const startOfCalendar = new Date(startOfMonth);
    startOfCalendar.setDate(startOfMonth.getDate() - ((startOfMonth.getDay() + 5) % 7));

    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const weeks = [];

    let day = new Date(startOfCalendar);

    while (day <= endOfMonth || day.getDay() !== 2) { // Continue until the last day of the month is a Sunday
        weeks.push(day.toISOString().slice(0, 10));
        day.setDate(day.getDate() + 1);
    }

    const prevMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const nextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    return (
        <div className="calendar">
            <div className="calendar-month-selection">
                <button onClick={prevMonth}>Prev</button>
                <h3>
                    {currentDate.toLocaleString('default', {month: 'long'})} {currentDate.getFullYear()}
                </h3>
                <button onClick={nextMonth}>Next</button>
            </div>
            <div className="calendar-grid">
                {daysOfWeek.map((day) => (
                    <div key={day} className="calendar-day">
                        {day}
                    </div>
                ))}
                {weeks.map((date, index) => {
                    const dayDate = new Date(date);
                    const isCurrentMonth = dayDate.getMonth() === currentDate.getMonth();
                    const today = new Date();
                    const isToday = dayDate.getFullYear() === today.getFullYear() && dayDate.getMonth() === today.getMonth() && dayDate.getDate() === today.getDate()
                    return (
                        <div
                            key={index}
                            className={`calendar-date ${isCurrentMonth ? 'current-month' : 'other-month'} ${isToday ? 'is-today' : ''}`}
                        >
                            <span className='date-number'>{dayDate.getDate()}</span>
                            {(eventsByDate[dayDate] ?? []).map(e => {
                                return <>
                                    <div className='calendar-event' onDoubleClick={_ => navigate(`/app/venue/${venue.id}/event/${e.id}/home`)}>
                                        <h5>{e.title}</h5>
                                        <div className='hover-info'>
                                            <b>{e.title}</b>
                                            <br/>
                                            <p><b>Forventet gæster:</b></p>
                                            <p>{e.expected_guests}</p>
                                        </div>
                                    </div>
                                </>
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
