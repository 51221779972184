import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {roomElementAPI} from "../api";

const initialState = {
    elements: {},
    previewElement: null,
    contextMenu: null,
}

export const doListRoomElements = createAsyncThunk('element/doListRoomElements', (room_id) => {
    return roomElementAPI.list_room_elements(room_id)
})
export const doSetPreviewElement = createAsyncThunk('element/doSetPreviewElement', async (element) => {
    return element
})

export const doCreateElement = createAsyncThunk('element/doCreateElement', async (element) => {
    return element
})

export const doSetElementPropertyLocally = createAsyncThunk('element/doSetElementPropertyLocally', async ({element_id, key, value}) => {
    return {element_id, key, value}
})

export const doSetElementFromBackend = createAsyncThunk('element/doSetElementFromBackend', async (element) => {
    return element
})

export const doDeleteElement = createAsyncThunk('element/doDeleteElement', async (element_id) => {
    await roomElementAPI.delete_room_element(element_id)
    return element_id
})

export const doSetContextMenu = createAsyncThunk('element/doSetContextMenu', async (context) => {
    return context
})

export function elementDraggedOverRoom(element) {
    return async function elementDraggedOverRoom(dispatch, getState){
        dispatch(doSetPreviewElement(element))
    }
}

export const elementDroppedOnRoom = (element) => async (dispatch, getState) => {
    const createdElement = await roomElementAPI.create_room_element(
        getState().room.active_room_id,
        element.type,
        element.properties
    )
    console.log('CREATED ELEMENT', createdElement)
    dispatch(doCreateElement(createdElement))
    dispatch(doSetPreviewElement(null))
}

export async function doSaveDirtyElementsToBackend(dispatch, getState) {
    const state = getState();
    const dirty_elements = Object.values(state.element.elements).filter(t => t.dirty)
    return await Promise.all(dirty_elements.map(async element => {
        return await roomElementAPI.update_room_element(element.id, element.properties).then(savedElement => {
            console.log('Saved element', savedElement)
            dispatch(doSetElementFromBackend(savedElement))
        })
    }))
}

const elementSlice = createSlice({
    name: 'element',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(doListRoomElements.fulfilled, (state, action) => {
                state.elements = action.payload.reduce((acc, element) => {
                    acc[element.id] = element
                    return acc
                }, {})
            })

            .addCase(doSetPreviewElement.fulfilled, (state, action) => {
                state.previewElement = action.payload
            })

            .addCase(doCreateElement.fulfilled, (state, action) => {
                state.elements = {...state.elements, [action.payload.id]: action.payload}
            })

            .addCase(doSetElementPropertyLocally.fulfilled, (state, action) => {
                const {element_id, key, value} = action.payload
                const existingElement = state.elements[element_id]
                state.elements[element_id] = {...existingElement, properties: {...existingElement.properties, [key]: value}, dirty: true}
            })

            .addCase(doSetElementFromBackend.fulfilled, (state, action) => {
                state.elements[action.payload.id] = action.payload
            })

            .addCase(doDeleteElement.fulfilled, (state, action) => {
                if (state.contextMenu === action.payload) {
                    state.contextMenu = null;
                }
                delete state.elements[action.payload]
            })

            .addCase(doSetContextMenu.fulfilled, (state, action) => {
                state.contextMenu = action.payload
            })
    }
})

export default elementSlice.reducer

export const selectElement = (element_id, isPreview) => state => {
    if (isPreview) {
        return state.element.previewElement
    }
    return state.element.elements[element_id]
}

export const selectElementContextMenu = state => {
    if (state.element.contextMenu === null) return null;
    const {element_ids} = state.element.contextMenu
    if (element_ids.length === 0) return null;
    return element_ids.map(id => state.element.elements[id])
}

export const selectTableContextMenu = state => {
    if (state.element.contextMenu === null) return null;
    const {table_ids} = state.element.contextMenu
    return table_ids.map(id => state.table.tables[id])
}

export const selectAllElements = state => Object.values(state.element.elements)
