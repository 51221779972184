import React, {useEffect, useMemo} from 'react'
import './tab-styles.css'
import ClickToShow from "../../../component/click-to-show/ClickToShow";
import CreateRoom from "../venue/create-room/CreateRoom";
import SimpleLink from "../../../component/simple-link/SimpleLink";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {doDeleteVenueRoom, doFetchVenueRooms, selectVenueRooms, selectVenues} from "../../../slice/venueSlice";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectVenueEvents} from "../../../slice/eventSlice";

export default function LokalerTab() {
    let {event_id, venue_id} = useParams();
    event_id = parseInt(event_id)
    venue_id = parseInt(venue_id)

    const dispatch = useDispatch();

    const venues = useSelector(selectVenues)
    const events = useSelector(selectVenueEvents(venue_id));
    const rooms = useSelector(selectVenueRooms(venue_id));

    useEffect(() => {
        dispatch(doFetchVenueRooms(venue_id));
    }, [venue_id, dispatch]);

    const venue = useMemo(() => {
        if (!venues || !venue_id) return null;
        return venues.find(v => v.id === venue_id)
    }, [venue_id, venues]);

    return (<section>
        <div className='header'>
            <h1>Lokaler</h1>
            <ClickToShow buttonText='Nyt lokale'>
                <CreateRoom/>
            </ClickToShow>
        </div>
        <div className='rooms'>
            {rooms.map(room => <div key={room.id} className='room-list-item'>
                <div className='room-img-wrapper'>
                    <img src={room.signed} alt={`preview of ${room.name}`}/>
                </div>
                <h3>{room.name}</h3>
                <p>{room.capacity}</p>
                {/*<Link to={`/app/room/${room.id}`}>Bordopsætning</Link>*/}
                <SimpleLink path={`/app/room/${room.id}`} value='Bordopsætning'/>

                <SimpleButton onClick={_ => {
                    if (window.confirm('Er du sikker på at du vil slette dette lokale?')) {
                        dispatch(doDeleteVenueRoom({venue_id, room_id: room.id}))
                    }
                }} value='Slet' dangerous/>
            </div>)}
        </div>
    </section>)
}
