import React from 'react'
import './simple-button.css'

export default function SimpleButton({
                                         className,
                                         value,
                                         onClick,
                                         hidden = false,
                                         disabled = false,
                                         loading = false,
                                         big = false,
                                         round,
                                         style,
                                         red = false,
                                         alwaysSelected = false,
                                         dangerous = false,
                                         isSubmit = false,
                                         ...rest
                                     }) {
    return <input
        style={style}
        hidden={hidden}
        type={isSubmit ? 'submit' : 'button'}
        className={`simple-button ${className} ${red ? 'red' : ''} ${round ? 'round' : ''} ${disabled ? 'disabled' : ''} ${loading && 'loading'} ${big ? 'big' : ''} ${dangerous ? 'dangerous' : ''} ${alwaysSelected ? 'always-selected' : ''}`}
        disabled={disabled}
        value={loading ? 'Loading...' : value}
        onClick={() => {
            if (isSubmit) return;
            if (loading) return;
            onClick()
        }}
        {...rest}
    />
}
