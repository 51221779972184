import React from 'react';
import './oval.css';
import {doSetContextMenu, doSetElementPropertyLocally, selectElement} from "../../../../../slice/elementSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveRoom} from "../../../../../slice/roomSlice";
import Dragable from "../../../../dragable-handle/Dragable";
import useElementBackend from "../../useElementBackend";

export default function Oval({element_id, isPreview = false, editable=false}) {
    const element = useSelector(selectElement(element_id, isPreview));
    const room = useSelector(selectActiveRoom)
    const dispatch = useDispatch()
    const {updateElementBackend} = useElementBackend()

    if (element === null || element.type !== 'oval') return;

    const {position, rotation, radius, backgroundColor, borderColor, text, fontSize} = element.properties;

    return <Dragable element_id={`${element_id}-element`}
                     key={`${element_id}-element`}
                     position={position}
                     rotation={rotation}
                     is_active={!isPreview && editable}
                     onContextMenu={e => {
                         e.preventDefault();
                         e.stopPropagation();
                         if (editable) dispatch(doSetContextMenu({
                             element_ids: [element_id],
                             table_ids: []
                         }))
                     }}
                     setPosition={p => {
                         dispatch(doSetElementPropertyLocally({
                             element_id,
                             key: 'position',
                             value: {
                                 x: p.x,
                                 y: p.y,
                             }
                         }))
                         updateElementBackend()
                     }}
                     className='oval' id={element_id} style={{
        width: `${radius * room.pixel_pr_meter}px`,
        height: `${radius * room.pixel_pr_meter}px`,
        backgroundColor: backgroundColor,
        fontSize: `${fontSize}px`,
        border: `1px solid ${borderColor}`,
        transform: `translate(-50%, -50%)`,
        pointerEvents: isPreview ? 'none' : '',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <div className='oval'>{text}</div>
    </Dragable>
}
