import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {doSetContextMenu, doSetElementPropertyLocally, selectElement} from "../../../../../slice/elementSlice";
import {doUpdateRoom, selectActiveRoom} from "../../../../../slice/roomSlice";
import Dragable from "../../../../dragable-handle/Dragable";
import Line from "../../../../line/Line";
import {Vector} from "vector2d";
import {VectorOperations} from "../../../../VectorOperations";
import useElementBackend from "../../useElementBackend";

import './distance-line.css';

export default function DistanceLine({element_id, isPreview = false, editable=false}) {
    const element = useSelector(selectElement(element_id, isPreview));
    const room = useSelector(selectActiveRoom)
    const dispatch = useDispatch();

    const {updateElementBackend} = useElementBackend()

    if (element === null || element.type !== 'line') return;

    const {position_start,
        position_end,
        displayDistance,
        pointColor,
        borderColor,
        lineColor
    } = element.properties;
    const vStart = new Vector(position_start.x, position_start.y);
    const vEnd = new Vector(position_end.x, position_end.y);
    const distPosition = VectorOperations.moveTowardsPercentage(
        vStart,
        vEnd,
        50
    );

    const length = Math.floor(vStart.clone().subtract(vEnd).length())

    return <>
        <Dragable
            className='distance-line-point'
            position={position_start}
            rotation={0}
            is_active={!isPreview && editable}
            onContextMenu={e => {
                e.preventDefault();
                e.stopPropagation();
                if (editable) dispatch(doSetContextMenu({
                    element_ids: [element_id],
                    table_ids: []
                }))
            }}
            setPosition={p => {
                dispatch(doSetElementPropertyLocally({
                    element_id,
                    key: 'position_start',
                    value: {
                        x: p.x,
                        y: p.y,
                    }
                }))
                updateElementBackend()
            }}
            style={{
                background: pointColor,
                border: `1px solid ${borderColor}`
            }}
            element_id={`${element_id}-element-from`}>
        </Dragable>
        <Dragable
            className='distance-line-point'
            position={position_end}
            rotation={0}
            is_active={!isPreview && editable}
            onContextMenu={e => {
                e.preventDefault();
                e.stopPropagation();
                if (editable) dispatch(doSetContextMenu({
                    element_ids: [element_id],
                    table_ids: []
                }))
            }}
            setPosition={p => {
                dispatch(doSetElementPropertyLocally({
                    element_id,
                    key: 'position_end',
                    value: {
                        x: p.x,
                        y: p.y,
                    }
                }))
                updateElementBackend()
            }}
            style={{
                background: pointColor,
                border: `1px solid ${borderColor}`
            }}
            element_id={`${element_id}-element-from`}>
        </Dragable>
        <Line
            start={new Vector(position_start.x, position_start.y)}
            end={new Vector(position_end.x, position_end.y)}

            color={lineColor}

            onContextMenu={e => {
                e.preventDefault();
                e.stopPropagation();
                if (editable) dispatch(doSetContextMenu({
                    element_ids: [element_id],
                    table_ids: []
                }))
            }}
        />
        {displayDistance && <div className='distance-line-text'
                                 onContextMenu={e => {
                                     e.preventDefault();
                                     e.stopPropagation();
                                     if (editable) dispatch(doSetContextMenu({
                                         element_ids: [element_id],
                                         table_ids: []
                                     }))
                                 }}
                                 style={{
                                     position: 'absolute',
                                     background: 'white',
                                     borderRadius: '8px',
                                     left: `${distPosition.x}px`,
                                     top: `${distPosition.y+5}px`,
                                     transform: 'translateX(-50%)',
                                     padding: '0 2px',
                                     zIndex: 5,
                                 }}>
            <p>{Math.floor(length / room.pixel_pr_meter * 1000)}mm</p>
        </div>}
    </>
}
