import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {
    doCreateTable,
    doFetchRoomTables,
    doFetchTableTemplates,
    doSetEditingTable,
    doSetHoveringTable, doSetStopDraggingTable,
    doSetStopHoveringTable,
    selectAllTables,
    selectEditingTableIds,
    selectHoveringTableId
} from "../../slice/tableSlice";
import './room-page.css'
import TableEditWindow from "./table-edit-window/TableEditWindow";
import {
    doFetchRoom, doSetActiveRoomId, selectActiveRoom,
} from "../../slice/roomSlice";
import RoomTable from "../../component/room-table/RoomTable";
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";
import Room from "../../component/room/Room";
import tableSeatIcon from '../../assets/table_and_chair_icon.svg';
import clear from '../../assets/clear-24px.svg';
import MeasuringTool from "../../component/measuring-tool/MeasuringTool";
import AlignmentControls from "../../component/room/alignment/AlignmentControls";
import ElementMenu from "../../component/room/element/element-menu/ElementMenu";
import SquareWithText from "../../component/room/element/representation/square-with-text/SquareWithText";
import DistanceLine from "../../component/room/element/representation/distance-line/DistanceLine";
import Oval from "../../component/room/element/representation/oval/Oval";
import useElements from "../../component/room/element/useElements";
import {doListRoomElements, selectElementContextMenu} from "../../slice/elementSlice";
import ElementContextMenu from "../../component/room/element/element-context-menu/ElementContextMenu";
import TableTemplateMenu from "../../component/room/table-template-menu/TableTemplateMenu";


export default function RoomPage() {
    const [leftMenuOpen, setLeftMenuOpen] = useState(false);
    const [settingScale, setSettingScale] = useState(false);

    const [previewTables, setPreviewTables] = useState(null);

    // copied table
    const [placingTable, setPlacingTable] = useState(null);

    let {room_id} = useParams();
    useEffect(() => {
        console.log('ACTIVE ROOM ID set to', room_id)
        dispatch(doSetActiveRoomId(room_id))
        dispatch(doFetchRoom(room_id))
    }, [room_id]);

    const dispatch = useDispatch()
    const tables = useSelector(selectAllTables);
    const room = useSelector(selectActiveRoom);
    const hoveringTableId = useSelector(selectHoveringTableId);
    const editingTableId = useSelector(selectEditingTableIds);
    const elements = useSelector(state => state.element.elements)

    const tableRef = useRef({});

    useEffect(() => {
        if (room === null) return;
        dispatch(doFetchRoomTables(room.id))
        dispatch(doListRoomElements(room.id))
        dispatch(doFetchTableTemplates({room_id: room.id, venue_id: null}))
    }, [dispatch, room]);


    // remove table refs for deleted tables, if any exist
    useEffect(() => {
        const tableIds = tables.map(t => t.id)
        Object.keys(tableRef.current).forEach(id => {
            if (!tableIds.includes(parseInt(id))) {
                tableRef.current[id] = null
            }
        })
    }, [tables]);

    const placeCopiedTable = useCallback(() => {
        dispatch(doCreateTable(placingTable));
        setPlacingTable(null);
        dispatch(doSetStopDraggingTable(-1))
    }, [dispatch, placingTable]);

    const placeTable = useCallback((placing) => {
        dispatch(doCreateTable(placing));
        dispatch(doSetStopDraggingTable(-1));
    }, [dispatch, placingTable]);

    useKeyboardShortcut({
        shortcutKeys: ['Enter'], keyUpCallback: placeCopiedTable
    })

    const tryParseName = useCallback(name => {
        const nr = parseInt(name)
        if (isNaN(nr)) return '1';
        return nr + 1 + ''
    }, []);

    const {
        previewElement,
        createElement,
    } = useElements();
    const contextMenuElement = useSelector(selectElementContextMenu);

    const openSeatCount = useMemo(() => {
        return tables.reduce((prev, curr) => prev + curr.max_seating, 0)
    }, [tables])

    if (!room) return null;

    if (previewTables) {
        // console.log(previewTables)
    }
    return (<div className='room-page'>
        <div className='top-bar'>
            <Link to={-1}>Tilbage</Link>
            <h4>Pladser: {openSeatCount}</h4>
            <h4>{room.name}</h4>
            <h4 className='scale-label'
                onClick={_ => setSettingScale(true)}>Scale (pixel pr meter): {room.pixel_pr_meter}</h4>
        </div>

        <div className={`menu ${leftMenuOpen ? 'open' : 'closed'}`}>
            <div className='table-scroll'>
                <table>
                    <thead>
                    <tr>
                        <th>Navn</th>
                        <th>Type</th>
                        <th>Pladser</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tables.map(t => {
                        const hovering = editingTableId === t.id || hoveringTableId === t.id
                        return <tr onClick={_ => dispatch(doSetEditingTable([t.id]))}
                                   className={`${hovering && 'hovering'}`} key={t.id}
                                   onMouseEnter={_ => dispatch(doSetHoveringTable(t.id))}
                                   onMouseLeave={_ => dispatch(doSetStopHoveringTable(t.id))}>
                            <td>{t.name}</td>
                            <td>{t.type}</td>
                            <td>{t.max_seating}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </div>

        <div className={`menu-toggle ${leftMenuOpen ? 'open' : 'closed'}`}
             style={{backgroundImage: `url(${leftMenuOpen ? clear : tableSeatIcon})`}}
             onClick={_ => setLeftMenuOpen(p => !p)}>
            <span>Bordliste</span>
        </div>


        <div className='room-wrapper' onContextMenu={e => {
            if (e.ctrlKey) {
                e.preventDefault();
                e.stopPropagation();
            }
        }}>
            <Room room_id={room_id}
                  dragToSelect
                  onDragStart={e => {
                      const createTableData = e.dataTransfer.getData('application/tableDragCreate')
                      if (createTableData) {
                          e.stopPropagation();
                          setPreviewTables(JSON.parse(createTableData))
                      }
                      console.log('DRAG START', createTableData)
                  }}
                  onDragOver={e => {
                      e.preventDefault();
                      if (e.dataTransfer.getData('application/tableDragCreate') && previewTables) {
                          e.stopPropagation();
                          setPreviewTables(prev => {
                              const firstTable = prev[0]

                              return prev.map(t => {
                                  const positionOffset = {
                                      x: t.position.x - firstTable.position.x,
                                      y: t.position.y - firstTable.position.y
                                  }
                                  return {
                                      ...t,
                                      position: {
                                          x: e.nativeEvent.offsetX + positionOffset.x,
                                          y: e.nativeEvent.offsetY + positionOffset.y
                                      }
                                  }
                              })
                          })
                          return;
                      }

                      const creatingNewElement = e.dataTransfer.getData('creatingNewElement')
                      if (creatingNewElement) {
                          console.log('CREATING NEW ELEMENT', creatingNewElement)
                          previewElement(JSON.parse(creatingNewElement), {
                              x: e.nativeEvent.offsetX,
                              y: e.nativeEvent.offsetY
                          })
                          return;
                      }
                      dispatch(doSetHoveringTable(-1));
                  }}
                  onDrop={e => {
                      const creatingNewElement = e.dataTransfer.getData('creatingNewElement')
                      if (creatingNewElement) {
                          createElement(JSON.parse(creatingNewElement), {
                              x: e.nativeEvent.offsetX,
                              y: e.nativeEvent.offsetY
                          })
                          e.dataTransfer.clearData('creatingNewElement')
                          return;
                      }

                      const createTableData = e.dataTransfer.getData('application/tableDragCreate')
                      console.log('DROP', createTableData)
                      const tables = JSON.parse(createTableData)
                      const firstTable = tables[0]
                      let nextName = Math.max(...tables.map(t => parseInt(t.name))) + 1
                      if (isNaN(nextName)) nextName = 1
                      tables.forEach(t => {
                          const positionOffset = {
                              x: t.position.x - firstTable.position.x,
                              y: t.position.y - firstTable.position.y
                          }
                          placeTable({
                              ...t,
                              id: -1,
                              room_id,
                              name: nextName++ + '',
                              position: {
                                  x: e.nativeEvent.offsetX + positionOffset.x,
                                  y: e.nativeEvent.offsetY + positionOffset.y
                              }
                          })
                      })
                      setPreviewTables(null)
                  }}>
                {tables.map(t => <RoomTable key={t.id}
                                            table={t}
                                            custRef={el => tableRef[t.id] = el}
                                            room={room}/>)}

                <SquareWithText element_id={null} isPreview={true}/>
                <DistanceLine element_id={null} isPreview={true}/>
                <Oval element_id={null} isPreview={true}/>

                {Object.values(elements).map(e => {
                    if (e.type === 'square') {
                        return <SquareWithText key={e.id} element_id={e.id} editable/>
                    }
                    if (e.type === 'line') {
                        return <DistanceLine key={e.id} element_id={e.id} editable/>
                    }
                    if (e.type === 'oval') {
                        return <Oval key={e.id} element_id={e.id} editable/>
                    }
                })}
                <TableEditWindow editingVenue={!!room.venue_id} editingEvent={!!room.event_id} />
                {previewTables && previewTables.map((t, i) => <RoomTable pointer_events={false} key={`table_preview-${i}`}
                                                                         table={t} room={room}/>)}
            </Room>
            {settingScale && <MeasuringTool room_id={room_id} closeSetScale={_ => setSettingScale(false)}/>}
        </div>

        <AlignmentControls/>

        <ElementContextMenu/>

        <TableTemplateMenu/>
        <ElementMenu/>
    </div>)
}
