import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {
    doFetchEvent,
    selectEventById, selectLocallyUpdatedEvent,
} from "../../../slice/eventSlice";
import {doGetUser} from "../../../slice/userSlice";
import {toDanishDate} from "../../../component/date_functions";
import Tabs from "../../../component/tabs/Tabs";
import './event-page-wrapper.css'

export default function EventPageWrapper() {
    const {event_id, venue_id} = useParams()

    const currentEvent = useSelector(selectEventById(event_id))
    const updatedEvent = useSelector(selectLocallyUpdatedEvent)

    // Tab
    const [selectedTab, setSelectedTab] = useState(null)

    const dispatch = useDispatch()

    const urlToTabMap = useMemo(() => ({
        'home': 'Hjem',
        'venue-client': 'Venue klienter',
        'dietary': 'Diæter',
        'waiter-export': 'Waiter Export',
        'door-list': 'Dør liste',
        'settings': 'Indstillinger',
    }), []);

    const tabToUrlMap = useMemo(() => {
        const tabToUrl = {}
        for (const [key, value] of Object.entries(urlToTabMap)) {
            tabToUrl[value] = key
        }
        return tabToUrl
    }, [urlToTabMap]);

    const url = window.location.href;
    const currentUrlTab = url.split('/').pop();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doFetchEvent(event_id));
    }, [dispatch, event_id]);

    useEffect(() => {
        if (selectedTab === null) {
            setSelectedTab(urlToTabMap[currentUrlTab]);
            return;
        }
        const selectedPage = tabToUrlMap[selectedTab]
        if (currentUrlTab !== selectedPage) {
            navigate(`/app/venue/${venue_id}/event/${event_id}/${selectedPage}`)
        }
    }, [currentUrlTab, urlToTabMap, selectedTab, navigate, tabToUrlMap]);

    if (!currentEvent || !updatedEvent) {
        return <div>Loading...</div>
    }

    return (<div className={'event-page-wrapper'}>
        <div className='header'>
            <div className='name-wrapper'>
                <a href={`/app/venue/${venue_id}/events`}>Tilbage til venue</a>
                <h1>{currentEvent.title}</h1>
                <h5>{toDanishDate(currentEvent.date)}</h5>
            </div>
        </div>

        <Tabs tabTitles={Object.values(urlToTabMap)}
              selected={urlToTabMap[currentUrlTab]}
              setSelected={setSelectedTab}
        />

        <Outlet/>
    </div>)
}
