import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {EventApi, RoomAPI} from "../api";
import {Vector} from "vector2d";

const initialState = {
    rooms: {},
    loading: false,
    zoom: 1,
    naturalImageSize: null,
    clientImageSize: null,
    active_room_id: null,
}

export const doFetchRoom = createAsyncThunk('room/fetch', async (room_id) => {
    return await RoomAPI.get_room_by_id(room_id)
})

export const doFetchEventRoom = createAsyncThunk('room/doFetchEventRoom', async (room_id) => {
    return await EventApi.get_event_room(room_id)
})

export const doFetchAllRooms = createAsyncThunk('room/doFetchAllRooms', async () => {
    return await RoomAPI.list_all_rooms()
})

export const doFetchEventRooms = createAsyncThunk('event/doFetchEventsRooms', async event_id => {
    return EventApi.list_rooms_by_event_id(event_id)
})

export const doUpdateRoom = createAsyncThunk('room/update', async ({room_id, values}) => {
    return await RoomAPI.update_room(room_id, values)
})

export const doCreateRoom = createAsyncThunk('room/create', async ({venue_id, name, width, height}) => {
    return await RoomAPI.create(
        venue_id,
        name,
        0,
        0,
        width,
        height,
    )
})

export const doSetZoom = createAsyncThunk('room/setZoom', async (zoom) => {
    return new Promise(res => res(zoom))
})
export const setExternalNaturalImageSize = createAsyncThunk('room/setNaturalImageSize', async (size) => {
    return size
})
export const setExternalClientImageSize = createAsyncThunk('room/setClientImageSize', async (size) => {
    return size
})

export const doSetActiveRoomId = createAsyncThunk('room/setActiveRoomId', async (room_id) => {
    return room_id
})


const roomSlice = createSlice({
    name: 'room',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(doFetchRoom.fulfilled, (state, action) => {
                const fetchedRoom = action.payload
                state.rooms[fetchedRoom.id] = fetchedRoom
            })

            .addCase(doFetchEventRoom.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchEventRoom.fulfilled, (state, action) => {
                if (!action.payload) return;
                const room = action.payload
                state.rooms[room.id] = room
                state.active_room_id = room.id
                state.loading = false
            })

            .addCase(doFetchAllRooms.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchAllRooms.fulfilled, (state, action) => {
                action.payload.forEach(room => {
                    state.rooms[room.id] = room
                })
                state.loading = false
            })

            .addCase(doUpdateRoom.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doUpdateRoom.fulfilled, (state, action) => {
                const updated = action.payload
                state.rooms[updated.id] = updated
                state.loading = false
                state.active_room_id = updated.id
            })

            .addCase(doCreateRoom.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doCreateRoom.fulfilled, (state, action) => {
                const newRoom = action.payload
                state.rooms[newRoom.id] = newRoom
                state.loading = false
            })

            .addCase(doSetZoom.fulfilled, (state, action) => {
                state.zoom = action.payload
            })
            .addCase(setExternalNaturalImageSize.fulfilled, (state, action) => {
                state.naturalImageSize = action.payload
            })
            .addCase(setExternalClientImageSize.fulfilled, (state, action) => {
                state.clientImageSize = action.payload
            })

            .addCase(doSetActiveRoomId.fulfilled, (state, action) => {
                state.active_room_id = action.payload
            })
    }
})

export default roomSlice.reducer

export const selectRoomById = id => state => {
    return state.room.rooms[id] || {}
}
export const selectActiveRoom = createSelector([state => state.room.active_room_id, state => state.room.rooms], (room_id, rooms) => {
    return rooms[room_id] || null
})

export const selectRoomLoading = state => {
    return state.room.loading
}

export const selectZoom = state => {
    return state.room.zoom
}
export const selectEventRoom = state => {
    return state.room.event_room
}

export const selectVenueRooms = venue_id => state => {
    return Object.values(state.room.rooms).filter(r => r.venue_id === venue_id)
}

export const selectNaturalImageSize = state => {
    const v = state.room.naturalImageSize
    if (!v) return null
    return new Vector(v.x, v.y)
}
export const selectClientImageSize = state => {
    const v = state.room.clientImageSize
    return new Vector(v.x, v.y)
}
