import React, {useEffect, useMemo, useState} from 'react'
import {useParams} from "react-router-dom";
import {EventApi} from "../../../api";
import './dietary-overview.css'
import SimpleButton from "../../../component/simple-button/SimpleButton";


export default function DietaryOverview() {
    const [data, setData] = useState(null);
    const {event_id} = useParams()

    useEffect(() => {
        EventApi.list_dietary_overview(event_id).then(setData)
    }, [event_id]);

    const copyable = useMemo(() => {
        if (!data) return ''

        const rows = data.map(g => {
            return [g.waiter, g.table, g.name, g.dietary, g.wheelchairs].join('\t')
        })

        return ['Tjener\tBord\tGæst navn\tDietary\tKørestol', ...rows].join('\n')
    }, [data]);

    const hasGuests = useMemo(() => data && data.length !== 0, [data])

    return <div className='dietary-overview'>
        {hasGuests && <SimpleButton value='Kopier' onClick={() => {navigator.clipboard.writeText(copyable)}} />}
        <table>
            <thead><tr>
                <th>Tjener</th>
                <th>Bord</th>
                <th>Gæst navn</th>
                <th>Dietary</th>
                <th>Kørestol</th>
            </tr></thead>
            <tbody>
            {data && data.map(d => <tr key={`${d.guest_id}-${d.table}`}>
                <td>{d.waiter}</td>
                <td>{d.table}</td>
                <td>{d.name}{d.is_group ? <b>*</b> : ''}</td>
                <td>{d.dietary}</td>
                <td>{d.wheelchairs}</td>
            </tr>)}
            {!hasGuests && <tr><td colSpan={5}>Ingen gæster på eventet - eventuelt ingen gæster med specielle diæter.
            For at ændre på gæsters præferencer - gå til gæstelisten</td></tr>}
            </tbody>
        </table>
        {hasGuests && <p><b>*</b>En del af en gruppe delt over flere borde.</p>}
    </div>
}
