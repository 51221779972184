import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SharedMessageApi} from "../../api";
import debounce from "lodash.debounce";
import './shared-message.css';

export default function SharedMessage({
                                          message_id,
                                          event_id,
                                          venue_id,
                                          message_title,
                                          message_description
                                      }) {
    const [localMessage, setLocalMessage] = useState({message: ''});

    useEffect(() => {
        SharedMessageApi.list_message(message_id, event_id, venue_id).then(res => {
            if (res !== null) setLocalMessage(res);
        });
    }, []);

    const updateMessageBackend = useCallback((updated_msg) => {
        SharedMessageApi.create_or_update(message_id, event_id, venue_id, updated_msg)
    }, [message_id, event_id, venue_id]);

    const _updateMessage = useMemo(() => debounce(updateMessageBackend, 800), [updateMessageBackend]);

    const setMessage = useCallback((msg) => {
        _updateMessage(msg)
        setLocalMessage(prev => ({...prev, message: msg}))
    }, [_updateMessage]);

    return (<div className='shared-message'>
        <h1>{message_title}</h1>
        <h5>{message_description}</h5>
        <textarea value={localMessage.message} onChange={e => setMessage(e.target.value)}/>
    </div>)
}
