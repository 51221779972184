import React, {useState} from 'react'
import './k-file-drop.css'
import uploadLogo from '../../assets/cloud-upload.svg'

export default function KFileDrop({didSelectFile, displayFiles = true, text = null, isImages = false, accept=''}) {
    const [selectedFiles, setSelectedFiles] = useState({});
    const [dragging, setDragging] = useState(false);

    const dragEnter = (e) => {
        e.preventDefault()
        setDragging(true);
    };
    const dragLeave = (e) => {
        e.preventDefault()
        setDragging(false);
    };
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        setDragging(false);
        const filtered = []
        Array.from(files).forEach(f => {
            filtered.push(f)
        })

        addFiles(filtered)
    };

    function addFiles(files) {
        const selectedDict = {}
        files.forEach(f => {
            selectedDict[f.name] = f
        });
        let curr = {...selectedFiles, ...selectedDict}
        setSelectedFiles(prev => ({
            ...prev,
            ...curr
        }));
        files.forEach(file => {
            let reader = new FileReader();
            reader.onloadend = () => {
                curr = {
                    ...curr,
                    [file.name]: {
                        ...file,
                        loading: true
                    }
                };
                const ok = didSelectFile(file.name, reader.result);
                if (ok === false) {
                    delete curr[file.name]
                } else {
                    curr = {
                        ...curr,
                        [file.name]: {
                            ...file,
                            data: reader.result,
                            loading: false
                        }
                    };
                }
                setSelectedFiles(prev => ({
                    ...prev,
                    ...curr
                }));
            };

            reader.readAsDataURL(file)
        });
    }

    function handleFileUpload(e) {
        console.log('UPLOAD')
        console.log(e)
        e.preventDefault();
        addFiles(Array.from(e.target.files))
    }

    return <div className='file-upload-page'>
        <div className='cc-file-upload'>
            <div className={`upload-field ${dragging ? 'dragging' : ''}`} onDragEnter={dragEnter}
                 onDragLeave={dragLeave}
                 onDragOver={e => e.preventDefault()}
                 onDrop={fileDrop}>
                <img src={uploadLogo} alt='drop files here'/>
                <p>{text === null ? 'Drag and drop filer her' : text}</p>
                {accept !== '' && <p>Tilladte filtyper: {accept.replace(/,/g, '')}</p>}
                <label className={`styled-file-selector ${dragging ? 'dragging' : ''}`}>
                    <input className="file-selector"
                           type="file" accept={accept}
                           onChange={handleFileUpload} />
                </label>
            </div>
            {displayFiles && <div className='file-list'>
                <table className='wpl-table'>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Status</th>
                        {isImages && <th>Image</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(selectedFiles).map(fileName => <tr key={fileName}>
                        <td>{fileName}</td>
                        <td>{selectedFiles[fileName].lastModified}</td>
                        <td>{selectedFiles[fileName].loading ? 'loading' : 'done'}</td>
                        {isImages && <td><img src={selectedFiles[fileName].data}/></td>}
                    </tr>)}
                    {Object.keys(selectedFiles).length === 0 && <tr>
                        <td colSpan={3}>No files</td>
                    </tr>}
                    </tbody>
                </table>
            </div>}
        </div>
    </div>
}
