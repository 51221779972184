import React from 'react'
import {Link, useNavigate} from "react-router-dom";
import './room-list.css'


export default function RoomList({rooms}) {
    return (<div className='room-list'>
        {rooms.map(room => <div key={room.id} className='room-list-item'>
            <img src={room.signed} alt={`preview of ${room.name}`}/>
            <div className='details'>
                <h3>{room.name}</h3>
                <p>{room.capacity}</p>
                <Link to={`/app/room/${room.id}`}>Bordopsætning</Link>
            </div>
        </div>)}
    </div>)
}
