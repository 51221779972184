export const VectorOperations = {
    moveTowardsPercentage(from, to, percentage) {
        const diff = to.clone().subtract(from)
        const half = diff.clone().multiplyByScalar(percentage/100)

        return from.clone().add(half)
    },
    moveTowardsPixels(from, to, pixels) {
        const dir = to.clone().subtract(from).normalize()

        return from.clone().add(dir.multiplyByScalar(pixels))
    },
    angle(v) {
        if (!v) return 0
        const norm = v.clone().normalize()
        return Math.atan2(norm.y, norm.x)
    }
}
