import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {createRoomError, createRoomLoading, doCreateVenueRoom} from "../../../../slice/venueSlice";
import InputField from "../../../../component/input-field/InputField";
import KFileDrop from "../../../../component/k-file-drop/KFileDrop";
import SimpleButton from "../../../../component/simple-button/SimpleButton";
import {useNavigate, useParams} from "react-router-dom";
import './create-room.css';


export default function CreateRoom() {
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [width, setWidth] = useState(17)
    const [height, setHeight] = useState(55)
    const [floorplanImage, setFloorplanImage] = useState({})
    const [creatingRoomLocally, setCreatingRoomLocally] = useState(false);

    let {venue_id} = useParams()
    venue_id = parseInt(venue_id)

    const navigate = useNavigate()

    const createLoading = useSelector(createRoomLoading)
    const createError = useSelector(createRoomError)

    const submitCreateRoom = useCallback(() => {
        let args = {
            venue_id,
            name,
            ...floorplanImage,
            position_x: 0,
            position_y: 0
        }
        if (width) args.width = width
        if (height) args.width = width
        dispatch(doCreateVenueRoom(args))
        setCreatingRoomLocally(true)
    }, [dispatch, venue_id, name, width, height, floorplanImage]);

    const uploadedImage = useCallback((image_name, image_data) => {
        console.log('uploaded image', image_name, image_data)
        setFloorplanImage({image_name, image_data})
    }, []);

    useEffect(() => {
        if (!createLoading && creatingRoomLocally) {
            setCreatingRoomLocally(false);

            if (!createError) {
                setName('')
                setWidth('')
                setHeight('')
                setFloorplanImage({})
                navigate(`/app/venue/${venue_id}/events`)
            }
        }
    }, [creatingRoomLocally, createLoading, createError]);

    return (<div className='room-popup'>
        <div className='horizontal'>
            <div className='input-wrapper'>
                <h1>Opretter nyt lokale</h1>

                <div className='horizontal'>
                    <InputField value={name} title={'Lokale navn'} onChanged={setName}/>
                </div>
                {/*<InputField value={width} title={'Bredde i meter'} onChanged={setWidth}/>*/}
                {/*<InputField value={height} title={'Højde i meter'} onChanged={setHeight}/>*/}

                {floorplanImage.image_data &&
                    <img src={floorplanImage.image_data} alt={`selected floor plan: ${floorplanImage.image_name}`}/>}
                <KFileDrop text={'Upload billede af salplanen her'}
                           displayFiles={false} isImages didSelectFile={uploadedImage}
                           accept={".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff"}/>

            </div>
            {/*<div className='example-wrapper'>*/}
            {/*    <div className='example' style={{*/}
            {/*        width: `calc(${(parseInt(width) / (parseInt(width) + parseInt(height))) * 100}% - 18px)`,*/}
            {/*        height: `calc(${(parseInt(height) / (parseInt(width) + parseInt(height))) * 100}% - 18px)`,*/}
            {/*    }}>*/}
            {/*        {floorplanImage.image_data &&*/}
            {/*            <img src={floorplanImage.image_data} alt={`selected floor plan: ${floorplanImage.image_name}`}/>}*/}
            {/*        <p className={`marker width`}>{width}m</p>*/}
            {/*        <p className={`marker height`}>{height}m</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
        <SimpleButton value='CREATE' big onClick={submitCreateRoom} loading={createLoading}/>
    </div>)
}
