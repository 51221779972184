import React, {useMemo, useState} from 'react'
import './tab-styles.css'
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectVenues} from "../../../slice/venueSlice";
import {selectVenueEvents} from "../../../slice/eventSlice";
import burgerMenu from '../../../assets/burger-menu-icon.png';
import Calendar from "../../../component/calendar/calendar";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import CreateNewEvent from "../../../component/create-new-event/CreateNewEvent";

export default function EventsTab() {
    const [selectedLayout, setSelectedLayout] = useState('list');
    const [eventFilter, setEventFilter] = useState('future');
    const [eventSearch, setEventSearch] = useState('');
    const [creatingNewEvent, setCreatingNewEvent] = useState(false)

    let {event_id, venue_id} = useParams();
    event_id = parseInt(event_id)
    venue_id = parseInt(venue_id)

    const navigate = useNavigate();

    const venues = useSelector(selectVenues)
    const events = useSelector(selectVenueEvents(venue_id));

    const venue = useMemo(() => {
        if (!venues || !venue_id) return null;
        return venues.find(v => v.id === venue_id)
    }, [venue_id, venues]);

    return (<div className='event-tab'>
        <div className='layout-selection-wrapper'>
            <div className='layout-selection' onClick={_ => setSelectedLayout('list')}>
                <img src={burgerMenu} alt='liste' className='small-icon'/>
                Listevisning
            </div>
            <div className='layout-selection' onClick={_ => setSelectedLayout('calendar')}>
                <img src={burgerMenu} alt='liste' className='small-icon'/>
                Kalendervisning
            </div>
        </div>
        {selectedLayout === 'list' && <div className='event-list-wrapper'>
            <div className='event-filter-wrapper'>
                <input type='text' id='search' placeholder='Søg efter event' className='search-input'
                       value={eventSearch} onChange={e => {
                    setEventSearch(e.target.value)
                    setEventFilter('all')
                }}/>

                <SimpleButton value='Opret event' onClick={_ => setCreatingNewEvent(true)}/>
                <div className='grower'>&nbsp;</div>
                <CenteredPopup showPopup={creatingNewEvent} closePopup={setCreatingNewEvent}>
                    <CreateNewEvent callback={_ => setCreatingNewEvent(false)}/>
                </CenteredPopup>
                <SimpleButton className='event-filter-btn' value='Alle events' alwaysSelected={eventFilter !== 'future'}
                              onClick={_ => setEventFilter('all')}/>
                <SimpleButton className='event-filter-btn' value='Kommende' alwaysSelected={eventFilter === 'future'}
                              onClick={_ => setEventFilter('future')}/>
            </div>
            <table className='event-table'>
            <thead>
                <tr>
                    <th>Eventnavn</th>
                    <th>Dato</th>
                </tr>
                </thead>
                <tbody>
                {events
                    .filter(e => eventSearch !== '' || eventFilter === 'all' || new Date(e.date).setHours(23) > new Date())
                    .filter(e => e.title.toLowerCase().includes(eventSearch.toLowerCase()))
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .map(e =>
                        <tr
                            key={e.id}
                            onClick={_ => navigate(`/app/venue/${venue.id}/event/${e.id}/home`)}
                            className='event'>
                            <td><a href={`/app/venue/${venue.id}/event/${e.id}/home`}>{e.title}</a></td>
                            <td>{new Date(e.date).toDateString()}</td>
                            <td className='hover-info'>
                                <b>{e.title}</b>
                                <br/>
                                <p><b>Forventet gæster:</b></p>
                                <p>{e.expected_guests}</p>
                            </td>
                        </tr>)}
                </tbody>
            </table>
        </div>}
        {selectedLayout === 'calendar' && <div>
            <Calendar venue={venue} events={events}/>
        </div>}
    </div>)
}
