import {useCallback, useMemo} from 'react';
import {
    doSetMultiplePositionsLocally,
    doSetTablePositionLocally,
    doUpdateTable,
    thunkSaveDirtyTables
} from "../../slice/tableSlice";
import debounce from "lodash.debounce";
import {useDispatch} from "react-redux";

export default function useSetTablePosition() {
    const dispatch = useDispatch();

    const updateTableBackend = useCallback(() => {
        dispatch(thunkSaveDirtyTables)
    }, [dispatch]);

    const updateBackend = useMemo(() => {
        return debounce(updateTableBackend, 800)
    }, [updateTableBackend]);

    const setLocally =  useCallback((id, position) => {
        dispatch(doSetTablePositionLocally({table_id: id, position}))
    }, [dispatch]);

    const setMultipleLocally =  useCallback((tables) => {
        dispatch(doSetMultiplePositionsLocally(tables))
    }, [dispatch]);

    const updateBackendImmediately = useCallback((id, position) => {
        dispatch(doSetTablePositionLocally({table_id: id, position}))
        dispatch(doUpdateTable({
            table_id: id,
            values: {position: {x: Math.floor(position.x), y: Math.floor(position.y)}}
        }))
    }, [dispatch]);

    return {
        setLocally,
        setMultipleLocally,
        updateBackendImmediately,
        updateBackend
    }
}
