import React from 'react'
import './table-search-bar.css'
import {useDispatch, useSelector} from "react-redux";
import {doSetSeatingSearch, selectSeatingSearch} from "../../../slice/seatingSlice";

export default function TableSearchBar() {
    const dispatch = useDispatch()
    const value = useSelector(selectSeatingSearch)

    return (<input type='text'
                   placeholder='Søg på borde'
                   className='table-search-bar'
                   value={value}
                   onChange={v => dispatch(doSetSeatingSearch(v.target.value))}
    />)
}
