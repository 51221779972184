import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {generatePath, Link, Outlet, useParams} from "react-router-dom";
import './create-new-event.css'
import {doFetchVenueRooms, selectVenueById, selectVenueLoading, selectVenueRooms} from "../../slice/venueSlice";
import {doCreateEvent, doFetchVenueEvents, selectCreatingEvent, selectVenueEvents} from "../../slice/eventSlice";
import {doFetchTableTemplates} from "../../slice/tableSlice";
import InputField from "../input-field/InputField";
import SimpleButton from "../simple-button/SimpleButton";

export default function CreateNewEvent({callback}) {
    const dispatch = useDispatch()

    let {venue_id} = useParams()
    venue_id = parseInt(venue_id)

    const venue = useSelector(selectVenueById(venue_id));
    const events = useSelector(selectVenueEvents(venue_id));
    const rooms = useSelector(selectVenueRooms(venue_id));
    const creatingEvent = useSelector(selectCreatingEvent);
    const venueLoading = useSelector(selectVenueLoading);

    useEffect(() => {
        if (!venue_id) return;
        dispatch(doFetchVenueRooms(venue_id));
        dispatch(doFetchTableTemplates({venue_id, room_id: null}));
        dispatch(doFetchVenueEvents(venue_id))
    }, [dispatch, venue_id])

    // new event creation
    const [eventTitle, setEventTitle] = useState('')
    const [contactName, setContactName] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [eventDate, setEventDate] = useState('')
    const [expectedGuests, setExpectedGuests] = useState('')
    const [eventRooms, setEventRooms] = useState([]);
    const [localEventLoading, setLocalEventLoading] = useState(false);

    // Tab
    const [selectedTab, setSelectedTab] = useState('Events')

    useEffect(() => {
        if (localEventLoading && !creatingEvent) {
            setLocalEventLoading(false);

            setEventTitle('')
            setContactName('')
            setContactPhone('')
            setContactEmail('')
            setEventDate('')
            setExpectedGuests('')
            setEventRooms([])
            if (callback !== null) callback(false)
        }
    }, [localEventLoading, creatingEvent, callback])

    const createEvent = useCallback(() => {
        setLocalEventLoading(true)
        dispatch(doCreateEvent({
            venue_id,
            title: eventTitle,
            contact_name: contactName,
            contact_phone: contactPhone,
            contact_email: contactEmail,
            date: eventDate,
            expected_guests: expectedGuests,
            room_ids: eventRooms
        }))
    }, [dispatch, venue_id, eventTitle, contactName, contactPhone, contactEmail, eventDate, expectedGuests, eventRooms]);

    if (!venue) return <p>Loading..</p>

    // <SimpleButton value='Opret event' onClick={_ => setCreatingNewEvent(true)} />
    return (<div className={'create-new-event'}>
        <h1>Nyt Event</h1>
        <InputField value={eventTitle} title={'Event Title'} onChanged={setEventTitle}/>
        <InputField value={eventDate} type='date' title={'Event Date'} onChanged={setEventDate}/>
        <InputField value={expectedGuests} title={'Expected Guests'} onChanged={setExpectedGuests}/>

        <h4>Room:</h4>
        <div className='room-selection'>
            {rooms.map(room => <p className={`room ${eventRooms.indexOf(room.id) !== -1 && 'selected'}`}
                                  key={room.id}
                                  onClick={_ => setEventRooms(prev => (prev.indexOf(room.id) !== -1) ? prev.filter(e => e !== room.id) : [...prev, room.id])}>
                {room.name}
            </p>)}
        </div>

        <SimpleButton disabled={eventRooms.length !== 1} value='CREATE' big onClick={createEvent}
                      loading={creatingEvent}/>
    </div>)
}
