import React from 'react'
import './tabs.css'

export default function Tabs({tabTitles, selected, setSelected}) {
    return (<div className='tab-container'>
        {tabTitles.map(t => {
            return <div key={t} onClick={() => setSelected(t)} className={`tab ${selected === t ? 'selected' : ''}`}>
                <span>{t}</span>
            </div>
        })}
    </div>)
}
