import React, {useCallback, useEffect, useState} from 'react'
import InputField from "../../../component/input-field/InputField";
import {adminAPI} from "../../../api";
import {useDispatch, useSelector} from "react-redux";
import {doFetchAdminVenues, selectAdminUsers} from "../../../slice/adminSlice";
import ClickToShow from "../../../component/click-to-show/ClickToShow";
import {prettyDate, prettyTimeDifference} from "../../../component/date_functions";


export default function AdminVenueSection({allVenues}) {
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [venueUsers, setVenueUsers] = useState(null);

    const [userSearch, setUserSearch] = useState('');
    const [onlyShowActiveUsers, setOnlyShowActiveUsers] = useState(false);

    const [newVenueName, setNewVenueName] = useState('');
    const [newVenueAddress, setNewVenueAddress] = useState('');

    const users = useSelector(selectAdminUsers);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!selectedVenue) return;
        adminAPI.list_venue_users(selectedVenue.id).then(setVenueUsers)
    }, [selectedVenue]);

    const onSelectedVenue = useCallback(name => {
        if (name === '') return;

        const matching = allVenues.filter(u => u.name === name)
        if (matching.length === 0) {
            console.log('Found no matching venues o.o', {name, venues: allVenues.map(u => u.name)})
            return;
        }
        if (matching.length > 1) {
            console.log('Matched several venues from name', {name, matching})
            window.alert('More than one user matched that name?')
            return;
        }
        setSelectedVenue(matching[0])
    }, [allVenues]);

    const addOrRemoveUser = useCallback((user_id, venue_id, do_add) => {
        if (do_add) {
            adminAPI.add_user_to_venue(user_id, venue_id).then(_ => {
                setVenueUsers(prev => ([...prev, users.find(u => u.id === user_id)]))
            })
        } else {
            adminAPI.remove_user_from_venue(user_id, venue_id).then(_ => {
                setVenueUsers(prev => prev.filter(p => p.id !== user_id))
            })
        }
    }, [users]);

    const filteredUsers = users &&
        users
            .filter(u => u.name.toLowerCase().includes(userSearch.toLowerCase()))
            .filter(u => !onlyShowActiveUsers || venueUsers.some(v => v.id === u.id))
            .sort((a, b) => b.id - a.id)

    return (<section>
        <h1>Venues</h1>
        <ClickToShow buttonText='New venue'>
            <h2>Opretter nyt event</h2>
            <InputField value={newVenueName} onChanged={setNewVenueName} placeholder='Navn'/>
            <InputField value={newVenueAddress} onChanged={setNewVenueAddress} placeholder='Adresse'/>
            <button onClick={_ => {
                adminAPI.create_venue(newVenueName, newVenueAddress).then(_ => {
                    dispatch(doFetchAdminVenues());
                    setNewVenueAddress('');
                    setNewVenueName('');
                })
            }}>Opret
            </button>
        </ClickToShow>
        <div className='search-area'>
            <InputField onlySelectableOptions
                        onChanged={onSelectedVenue}
                        options={allVenues.map(u => u.name)}
                        startWithFocus submit={console.log}/>
            <hr/>
            {selectedVenue && <div className='venue'>
                <h2>{selectedVenue.name}</h2>
                <input type='search' placeholder='Search users' value={userSearch}
                       onChange={t => setUserSearch(t.target.value)}/>

                <div>
                    <label>
                        Kun vis aktive brugere
                        <input type='checkbox' checked={onlyShowActiveUsers}
                               onChange={t => setOnlyShowActiveUsers(t.target.checked)}/>
                    </label>
                </div>
                <br/>

                {filteredUsers && venueUsers && <table>
                    <thead>
                    <tr>
                        <th>Navn</th>
                        <th>Rolle</th>
                        <th>Senest set</th>
                        <th></th>
                        <th>Aktiv</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredUsers.map(user => <tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{user.role}</td>
                        <td>{prettyTimeDifference(new Date(), new Date(user.login_timestamp))}</td>
                        <td>
                        </td>
                        <td><input type='checkbox'
                                   checked={venueUsers.some(v => v.id === user.id)}
                                   onChange={t => addOrRemoveUser(user.id, selectedVenue.id, t.target.checked)}
                        /></td>
                    </tr>)}
                    </tbody>
                </table>}
            </div>}
        </div>
    </section>)
}
