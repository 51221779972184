import React, {useCallback, useState} from 'react';

import './selected-tables-rect-seating.css';
import {useDispatch, useSelector} from "react-redux";
import {
    doSeatGuestWithCount,
    doSetHoveringTableOverride,
    selectSelectedElements,
    selectSelectedTableRect,
    selectSelectedTables
} from "../../../slice/tableSlice";
import {doSetContextMenu} from "../../../slice/elementSlice";
import {selectDraggingData} from "../../../slice/seatingSlice";
import {selectGuests} from "../../../slice/guestSlice";

export default function SelectedTablesRectSeating() {
    const dispatch = useDispatch();
    const selectedTablesRect = useSelector(selectSelectedTableRect);

    const selectedTables = useSelector(selectSelectedTables);
    const selectedElements = useSelector(selectSelectedElements);
    const guests = useSelector(selectGuests);

    const [statusText, setStatusText] = useState('')

    const draggingData = useSelector(selectDraggingData);

    const getSeatingPlanFromDrag = useCallback(() => {
        const groupCount = draggingData.count_pr_guest.filter(c => c > 1).length
        const individuals = draggingData.count_pr_guest.filter(c => c === 1).length

        if (groupCount > 0 && individuals > 0) {
            setStatusText('Kan ikke mikse grupper og enkeltpersoner')
            return;
        }
        if (groupCount > 1) {
            // setStatusText('Mikser de valgte grupper så vidt muligt')
            setStatusText('Kan ikke mikse grupper endnu')
            return;
        }
        if (individuals > 0) {
            setStatusText('Kan ikke individuelt placere enkeltpersoner endnu')
            return;
        }
        if (groupCount === 1) {
            setStatusText('Fylder borde op med den valgte gruppe')
        }


        const seatingPlan = {};
        let remainingTables = [...selectedTables]
        let currentTable = remainingTables.shift()

        // loop through count_pr_guest and guest_ids
        new Array(draggingData.count_pr_guest.length).fill(0).forEach((_, i) => {
            if (!currentTable) {
                setStatusText('Ikke nok plads på borde')
                return;
            }

            let count = draggingData.count_pr_guest[i]
            const guestId = draggingData.guest_ids[i]

            let overflow = 10;
            while (count > 0 && overflow > 0) {
                const alreadySeatedByPlan = seatingPlan[currentTable.id] || {}
                const previouslySeated = currentTable.seats || {}

                const totalSeated = {...alreadySeatedByPlan, ...previouslySeated}
                const alreadySeatedCountPlan = Object.values(totalSeated).reduce((acc, c) => acc + c, 0)
                const spotsLeftCurrentTable = currentTable.active_seats - alreadySeatedCountPlan

                if (spotsLeftCurrentTable <= 0) {
                    currentTable = remainingTables.shift();
                    if (!currentTable) break;
                    continue;
                }

                const guestsToSeat = Math.min(spotsLeftCurrentTable, count)
                seatingPlan[currentTable.id] = {
                    [guestId]: guestsToSeat
                }
                count -= guestsToSeat;
                overflow -= 1;

            }
        })

        return seatingPlan
    }, [draggingData, selectedTables, guests]);

    const onDragOver = useCallback(e => {
        e.preventDefault()
        const seatingPlan = getSeatingPlanFromDrag()
        if (!seatingPlan) return;
        dispatch(doSetHoveringTableOverride(seatingPlan))
    }, [dispatch, getSeatingPlanFromDrag]);

    const onDrop = useCallback(e => {
        e.preventDefault()
        const seatingPlan = getSeatingPlanFromDrag()
        if (!seatingPlan) return;
        Object.keys(seatingPlan).forEach(table_id => {
            Object.keys(seatingPlan[table_id]).forEach(guest_id => {
                const count = seatingPlan[table_id][guest_id]
                dispatch(doSeatGuestWithCount({
                    table_id: parseInt(table_id),
                    guest_id: parseInt(guest_id),
                    count
                }))
            })
        })
        dispatch(doSetHoveringTableOverride({}))
    }, [dispatch, getSeatingPlanFromDrag]);

    if (selectedTablesRect === null) return null;

    return <div className='selected-rect-seating'
                onDragOver={onDragOver}
                onDragLeave={_ => {
                    dispatch(doSetHoveringTableOverride({}))
                    setStatusText('')
                }}
                onDrop={onDrop}
                onContextMenu={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(doSetContextMenu({
                        element_ids: selectedElements.map(e => e.id),
                        table_ids: selectedTables.map(t => t.id),
                    }))
                }}
                style={{
                    left: `${selectedTablesRect.left}px`,
                    top: `${selectedTablesRect.top}px`,
                    width: `${selectedTablesRect.width}px`,
                    height: `${selectedTablesRect.height}px`,
                }}>
        <p className='status-text'>{statusText}</p>
    </div>
}
