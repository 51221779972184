import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as THREE from "three";
import './table-model.css';

export default function TableModel({
                                       width,
                                       length,
                                       radius,
                                       height,
                                       type = 'square',
                                       scaleToWidth,
                                       className,
                                       ...props
                                   }) {
    const wrapperRef = useRef(null);

    const [hovering, setHovering] = useState(false);
    const [animationProgress, setAnimationProgress] = useState(0);
    const [ANIMATION_LENGTH, _] = useState(2);

    const [sceneObjects, setSceneObjects] = useState(null);

    useEffect(() => {
        let animationFrameId;

        function animate() {
            animationFrameId = requestAnimationFrame(animate);
            setAnimationProgress(p => {
                if (hovering) {
                    if (p > ANIMATION_LENGTH) {
                        cancelAnimationFrame(animationFrameId);
                        return ANIMATION_LENGTH;
                    }
                    // ease-in function
                    const dist = 1 - p
                    return (p + dist / 30)
                } else {
                    if (p < 0) {
                        cancelAnimationFrame(animationFrameId);
                        return 0;
                    }
                    return (p - p / 30)
                }
            });
        }

        if (hovering && animationProgress < ANIMATION_LENGTH || !hovering && animationProgress > 0) {
            if (!animationFrameId) animate();
        } else {
            cancelAnimationFrame(animationFrameId);
        }

        return () => cancelAnimationFrame(animationFrameId);
    }, [hovering]);

    const progressSum = useMemo(() => {
        if (animationProgress > ANIMATION_LENGTH) return 1;
        return animationProgress / ANIMATION_LENGTH;
    }, [animationProgress, ANIMATION_LENGTH]);

    useEffect(() => {
        if (!sceneObjects) return;
        if (sceneObjects.table) {
            // sceneObjects.table.position.y = animationProgress * 0.3;
            // sceneObjects.table.rotation.y = animationProgress * 0.5 * Math.PI;

            sceneObjects.camera.position.z = 3 - 3 * progressSum;
            sceneObjects.camera.position.y = 3 - 2.5 * progressSum;
            sceneObjects.camera.lookAt(0, 0, 0);

            sceneObjects.renderer.render(sceneObjects.scene, sceneObjects.camera);
        }
    }, [progressSum, sceneObjects]);

    useEffect(() => {
        if (wrapperRef.current === null) return;

        width = parseFloat(width);
        length = parseFloat(length);
        height = parseFloat(height);
        radius = parseFloat(radius);

        const scene = new THREE.Scene();

        // Setting up camera
        const aspectRatio = window.innerWidth / window.innerHeight;

        const camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);
        camera.position.set(2, 3, 3);

        // cam look at table
        camera.lookAt(0, 0, 0)

        // Set up renderer
        const renderer = new THREE.WebGLRenderer({antialias: true});
        renderer.setSize(scaleToWidth, scaleToWidth);
        renderer.render(scene, camera);

        // remove all existing children
        while (wrapperRef.current.firstChild) {
            wrapperRef.current.removeChild(wrapperRef.current.firstChild);
        }
        wrapperRef.current.appendChild(renderer.domElement);

        {
            const skyColor = 0xB1E1FF;  // light blue
            const groundColor = 0xB97A20;  // brownish orange
            const intensity = 2
            const light = new THREE.HemisphereLight(skyColor, groundColor, intensity);
            scene.add(light);
        }

        const directionalLight = new THREE.DirectionalLight(0xffffff, 5);
        directionalLight.position.set(20, 500, 300);
        scene.add(directionalLight);

        function createTableTop() {
            const geometry = new THREE.BoxGeometry(length, 0.05, width);
            const material = new THREE.MeshPhysicalMaterial({color: 0xCCCCCC})
            return new THREE.Mesh(geometry, material);
        }

        function createTableLeg() {
            const geometry = new THREE.BoxGeometry(0.05, height, 0.05);
            const material = new THREE.MeshPhysicalMaterial({color: 0x333333})
            return new THREE.Mesh(geometry, material);
        }

        function createTable() {
            const table = new THREE.Group();

            let tableTop;

            if (type === 'Square') {
                tableTop = createTableTop();
                tableTop.position.y = height + 0.025;
                for (let i = 0; i < 4; i++) {
                    const leg = createTableLeg();
                    leg.position.y = height / 2;
                    leg.position.x = i % 2 === 0 ? -length / 2 + 0.025 : length / 2 - 0.025;
                    leg.position.z = i < 2 ? -width / 2 + 0.025 : width / 2 - 0.025;
                    table.add(leg);
                }
            } else {
                tableTop = new THREE.Mesh(
                    new THREE.CylinderGeometry(radius, radius, 0.05, 32),
                    new THREE.MeshPhysicalMaterial({color: 0xCCCCCC})
                );
                tableTop.position.y = height + 0.025;
                for (let i = 0; i < 4; i++) {
                    const leg = createTableLeg();
                    leg.position.y = height / 2;
                    if (i === 0) leg.position.x = -radius + 0.025;
                    if (i === 1) leg.position.x = radius - 0.025;
                    if (i === 2) leg.position.z = -radius + 0.025;
                    if (i === 3) leg.position.z = radius - 0.025;

                    table.add(leg);
                }
            }

            table.add(tableTop);
            return table;
        }

        const table = createTable();

        const floor = new THREE.Mesh(
            new THREE.PlaneGeometry(10, 15, 1, 10),
            new THREE.MeshPhysicalMaterial({color: 0x555555, side: THREE.DoubleSide})
        );
        floor.receiveShadow = true;
        floor.position.y = -0.025;
        floor.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);

        const backwall = new THREE.Mesh(
            new THREE.PlaneGeometry(10, 15, 1, 10),
            new THREE.MeshPhysicalMaterial({color: 0x555555, side: THREE.DoubleSide})
        );
        backwall.position.z = -7.5;
        backwall.position.y = -0.025;

        const otherBackwall = new THREE.Mesh(
            new THREE.PlaneGeometry(15, 30, 1, 10),
            new THREE.MeshPhysicalMaterial({color: 0x555555, side: THREE.DoubleSide})
        );
        otherBackwall.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2);
        otherBackwall.position.x = -5;
        otherBackwall.position.y = -0.025;

        scene.add(backwall)
        scene.add(otherBackwall)
        scene.add(floor)
        scene.add(table);
        renderer.render(scene, camera);

        setSceneObjects({
            table,
            floor,
            backwall,
            otherBackwall,
            camera,
            renderer,
            scene,
        });
    }, [wrapperRef.current, width, height, length, type, radius]);

    return (<div className={`model-wrapper ${className}`}
                 onMouseEnter={() => setHovering(true)}
                 onMouseLeave={e => {
                     setHovering(false);
                 }}
                 style={{transformOrigin: '0 0', width: `${scaleToWidth}px`}}
                 ref={wrapperRef}>
    </div>)
}