import React, {useState} from 'react';
import clear from '../../assets/clear-24px.svg'
import './bottom-page-popout.css';

export default function BottomPagePopoutMenu({children, leftOffset, menuTitle, ...props}) {
    const [menuOpen, setMenuOpen] = useState(false);

    return (<div className={`bottom-page-popout ${menuOpen ? 'open' : 'closed'}`} {...props} style={{
            left: `${leftOffset}px`,
            ...props.style
        }}>
            <div className={`header ${menuOpen ? 'open' : 'closed'}`} onClick={_ => setMenuOpen(true)}>
                <p>{menuTitle}</p>
                {menuOpen && <img src={clear} onClick={e => {
                    setMenuOpen(false);
                    e.stopPropagation()
                }}/>}
            </div>
            {menuOpen && children}
        </div>
    )
}