import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import './element-context-menu.css';
import {doSetContextMenu, selectElementContextMenu} from "../../../../slice/elementSlice";
import MouseEventHandler from "../../../mouse-event-handler/MouseEventHandler";
import useElements from "../useElements";

export default function ElementContextMenu() {
    const dispatch = useDispatch();
    const elements = useSelector(selectElementContextMenu);

    const {renderElementContextMenu} = useElements();

    if (elements === null) return null;

    return <>
        <MouseEventHandler
            mouseClick={() => dispatch(doSetContextMenu(null))}
            killAllOtherEvents style={{
            background: 'rgba(0, 0, 0, 0.4)',
        }} />
        <div className='element-context-menu'>
            {renderElementContextMenu(elements)}
        </div>
    </>
}