import { configureStore } from "@reduxjs/toolkit";

import eventReducer from './slice/eventSlice'
import userReducer from './slice/userSlice'
import tableReducer from './slice/tableSlice'
import guestReducer from './slice/guestSlice'
import venueReducer from './slice/venueSlice'
import roomReducer from './slice/roomSlice'
import seatingReducer from "./slice/seatingSlice";
import adminReducer from "./slice/adminSlice";
import stationReducer from "./slice/stationSlice";
import elementSlice from "./slice/elementSlice";

export default configureStore({
    reducer: {
        event: eventReducer,
        user: userReducer,
        table: tableReducer,
        guest: guestReducer,
        venue: venueReducer,
        room: roomReducer,
        seating: seatingReducer,
        admin: adminReducer,
        station: stationReducer,
        element: elementSlice,
    }
})
