import React, {useState} from 'react';
import TableModel from "../../../page/room/table-model/TableModel";
import './table-template-menu.css';
import {useSelector} from "react-redux";
import {selectVenueTableTemplates} from "../../../slice/tableSlice";
import CreateNewTableTemplate from "../../create-table-template/CreateNewTableTemplate";
import CenteredPopup from "../../centered-popup/CenteredPopup";
import {selectActiveRoom} from "../../../slice/roomSlice";
import BottomPagePopoutMenu from "../../bottom-page-popout/BottomPagePopout";

export default function TableTemplateMenu() {
    const [creatingNewTable, setCreatingNewTable] = useState(false);

    const room = useSelector(selectActiveRoom);

    const uniqueTables = useSelector(selectVenueTableTemplates);

    return (<>
            <CenteredPopup showPopup={creatingNewTable} closePopup={setCreatingNewTable}>
                <CreateNewTableTemplate room_id={room.id}
                                        venue_id={room.venue_id}
                                        isVenueTemplate={false}
                                        onClose={_ => setCreatingNewTable(false)}/>
            </CenteredPopup>

            <BottomPagePopoutMenu menuTitle='Tables' leftOffset={16}>
                <div className='model-list'>
                    {uniqueTables.map(t => <div key={t.id} draggable={true}
                                                className='table-3d-model-wrapper'
                                                onDragStart={e => {
                                                    e.dataTransfer.setData('application/tableDragCreate', JSON.stringify([t]))
                                                }}>
                        <TableModel length={parseFloat(t.length)}
                                    width={parseFloat(t.width)}
                                    radius={parseFloat(t.length)}
                                    height={parseFloat(t.height ? t.height : .8)}
                                    type={t.type}
                                    scaleToWidth={100}
                                    className={'table-3d-model'}
                        />
                        <div className='table-info'>
                            <h3>{t.name}</h3>
                            <p>({t.width}, {t.length})m dimensions</p>
                            <p>{t.max_seating} max</p>
                        </div>
                    </div>)}
                    <p onClick={_ => setCreatingNewTable(true)} className='create-new'>Opret nyt bord</p>
                </div>
            </BottomPagePopoutMenu>
        </>
    )

}