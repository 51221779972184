import {useCallback, useMemo} from 'react';
import debounce from "lodash.debounce";
import {useDispatch} from "react-redux";
import {doSaveDirtyElementsToBackend} from "../../../slice/elementSlice";

export default function useElementBackend() {
    const dispatch = useDispatch();

    const _debouncedElementBackend = useCallback(() => {
        dispatch(doSaveDirtyElementsToBackend)
    }, [dispatch]);

    const updateElementBackend = useMemo(() => {
        return debounce(_debouncedElementBackend, 800)
    }, [_debouncedElementBackend]);

    return {
        updateElementBackend
    }
}
