import React, {useCallback, useState} from 'react'

import './create-new-table-template.css';
import {doCreateTable, selectAllTables} from "../../slice/tableSlice";
import InputField from "../input-field/InputField";
import SimpleButton from "../simple-button/SimpleButton";
import TableModel from "../../page/room/table-model/TableModel";
import {useDispatch, useSelector} from "react-redux";

export default function CreateNewTableTemplate({room_id, venue_id, onClose}) {
    const [newTableName, setNewTableName] = useState('');
    const [newTableMax, setNewTableMax] = useState(8);
    const [newTableType, setNewTableType] = useState('Square');

    const [newTableLength, setNewTableLength] = useState(200);
    const [newTableWidth, setNewTableWidth] = useState(80);
    const [newTableRadius, setNewTableRadius] = useState(80);
    const [newTableHeight, setNewTableHeight] = useState(80);

    const dispatch = useDispatch()

    const onCreateTable = useCallback(() => {
        dispatch(doCreateTable({
            venue_id,
            room_id,
            name: newTableName,
            max_seating: newTableMax,
            length: newTableType === 'Square' ? parseFloat(newTableLength / 100) : parseFloat(newTableRadius / 100),
            width: newTableType === 'Square' ? parseFloat(newTableWidth / 100) : parseFloat(newTableRadius / 100),
            type: newTableType,
            position: {x: 100, y: 100},
            is_venue_template: true
        }))
        setNewTableName('');
        setNewTableMax(8);
        onClose()
    }, [dispatch, newTableName, newTableMax, room_id, venue_id, newTableLength, newTableWidth, newTableType, onClose, newTableRadius])


    return (<>
        <h2>Nyt bord</h2>
        <InputField title='Name' value={newTableName} onChanged={setNewTableName}/>
        <InputField title='Antal pladser' value={newTableMax} onChanged={setNewTableMax}/>
        <InputField title='Type' options={['Square', 'Round']} onlySelectableOptions value={newTableType}
                    onChanged={setNewTableType}/>

        {newTableType === 'Round' && <>
            <InputField title='Bordets radius (cm)' value={newTableRadius} onChanged={setNewTableRadius}/>
        </>}
        {newTableType === 'Square' && <>
            <InputField title='Bordets længde (cm)' value={newTableLength} onChanged={setNewTableLength}/>
            <InputField title='Bordet bredde (cm)' value={newTableWidth} onChanged={setNewTableWidth}/>
        </>}

        <InputField title='Højde (cm)' value={newTableHeight} onChanged={setNewTableHeight}/>
        <SimpleButton value='Opret' onClick={onCreateTable}/>

        <TableModel length={newTableLength / 100}
                    width={newTableWidth / 100}
                    radius={newTableRadius / 100}
                    height={newTableHeight / 100}
                    type={newTableType}
                    scaleToWidth={600}
        />
    </>)
}