import React, {useCallback, useEffect, useMemo, useState} from 'react'
import './seating.css'
import {useDispatch, useSelector} from "react-redux";
import {doFetchRoomTables, doUnseatGuests, selectAllTables} from "../../slice/tableSlice";
import GuestList from "../../component/guest-list/GuestList";
import {doFetchEventGuests, selectAllSelectedGuests, selectGuests} from "../../slice/guestSlice";
import {Link, useParams} from "react-router-dom";
import {doFetchEvent, selectCurrentEvent} from "../../slice/eventSlice";
import {
    doFetchEventRoom,
    doFetchRoom,
    selectActiveRoom
} from "../../slice/roomSlice";
import Room from "../../component/room/Room";
import SeatingTable from "../../component/seating-table/SeatingTable";
import PopupSeatingTable from "./popup-seating-table/PopupSeatingTable";
import {selectSeatingSearch} from "../../slice/seatingSlice";
import SeatingHeader from "../../component/seating-header/SeatingHeader";
import MovableWindow from "../../component/movable-window/MovableWindow";
import {doGetUser, selectUser} from "../../slice/userSlice";
import {doListRoomElements} from "../../slice/elementSlice";
import SquareWithText from "../../component/room/element/representation/square-with-text/SquareWithText";
import DistanceLine from "../../component/room/element/representation/distance-line/DistanceLine";
import Oval from "../../component/room/element/representation/oval/Oval";

export default function SeatingPage() {
    const [selectedTable, setSelectedTable] = useState(null);
    const {event_id} = useParams()

    const dispatch = useDispatch()
    const tables = useSelector(selectAllTables);

    const guests = useSelector(selectGuests)
    const seatingSearch = useSelector(selectSeatingSearch)
    const currentEvent = useSelector(selectCurrentEvent(event_id))
    const elements = useSelector(state => state.element.elements)

    const selectedGuests = useSelector(selectAllSelectedGuests)

    const user = useSelector(selectUser)
    const room = useSelector(selectActiveRoom);

    const selectedGuestsWithComment = useMemo(() => {
        return selectedGuests.filter(sg => !!sg.comment)
    }, [selectedGuests]);

    const guestIdMap = useMemo(() => {
        const mapped = {}
        guests.forEach(g => mapped[g.id] = g)

        return mapped
    }, [guests])

    useEffect(() => {
        if (!event_id) return;
        dispatch(doGetUser())
        dispatch(doFetchEvent(event_id));
        dispatch(doFetchEventRoom(event_id))
        dispatch(doFetchEventGuests(event_id))
    }, [dispatch, event_id]);

    useEffect(() => {
        if (room === null) return;

        dispatch(doFetchRoomTables(room.id))
        dispatch(doListRoomElements(room.id))
    }, [dispatch, room]);

    const filteredTables = useMemo(() => {
        if (!seatingSearch) {
            return tables
        }
        const lowerSearch = seatingSearch.toLowerCase()
        return tables.filter(t => {
            if (t.name.toLowerCase().indexOf(lowerSearch) !== -1) {
                return true
            }
            return Object.keys(t.seats).some(guest_id => {
                const g = guestIdMap[guest_id]
                if (!g) {
                    return false
                }
                const terms = [g.name.toLowerCase(), (g.dietary ?? '').toLowerCase(), (g.wheelchairs ?? '').toLowerCase(), (g.company ?? '').toLowerCase()]

                return terms.some(t => t.indexOf(lowerSearch) !== -1)
            });
        })
    }, [seatingSearch, tables, guestIdMap])

    const onDragOver = useCallback((e) => {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'link'
    }, []);

    const seating_locked = useMemo(() => {
        if (!currentEvent || !user) return false;
        return !!currentEvent.locked_seating && user.role === 'venue-client'
    }, [currentEvent]);

    if (room === null) return (<div>Loading...</div>);

    return (<div className='seating-page' onContextMenu={e => {
        if (e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();
        }
    }}>
        <SeatingHeader/>
        <div className='menu-wrapper' onDragOver={onDragOver} onDrop={e => {
            const data = e.dataTransfer.getData('application/seating')
            const {guest_ids, count} = JSON.parse(data)
            dispatch(doUnseatGuests({guest_ids}))
        }}>
            <GuestList
                guests={guests}
                className='seating-guest-list'
                showSearch
                simpleView
                displaySeated={false}
            />
            <div className='grower'/>
        </div>
        <div style={{position: 'relative'}}>
            <Room dragToSelect seating>
                {filteredTables.map(t => <SeatingTable
                    onClick={_ => setSelectedTable(t)}
                    key={t.id}
                    table={t}
                    room={room}/>)}

                {Object.values(elements).map(e => {
                    if (e.type === 'square') {
                        return <SquareWithText key={e.id} element_id={e.id}/>
                    }
                    if (e.type === 'line') {
                        return <DistanceLine key={e.id} element_id={e.id}/>
                    }
                    if (e.type === 'oval') {
                        return <Oval key={e.id} element_id={e.id}/>
                    }
                })}
            </Room>
        </div>

        {seating_locked && <div className='seating-locked-popup'>
            <h1>Seating locked</h1>
            <p>Please contact us if you think this is a mistake</p>
            <Link to={'/'}>Home</Link>
        </div>}
        {!seating_locked && <PopupSeatingTable onClose={_ => setSelectedTable(null)} table={selectedTable}/>}

        {<MovableWindow startX={1200}
                        title='Seating Comments'
                        className='guest-comment-popup' visible={selectedGuestsWithComment.length > 0}>
            <ul>
                {selectedGuestsWithComment.map(sg => <li key={sg.id}>{sg.name}: {sg.comment}</li>)}
            </ul>
        </MovableWindow>}
    </div>)
}
