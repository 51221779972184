import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import InputField from "../../../component/input-field/InputField";
import SimpleButton from "../../../component/simple-button/SimpleButton";

import {
    doDeleteTable,
    doSetEditingTable,
    doSetTableLocally,
    doUpdateTable,
    selectSelectedTableRect
} from "../../../slice/tableSlice";
import debounce from "lodash.debounce";
import './table-edit-window.css';
import MouseEventHandler from "../../../component/mouse-event-handler/MouseEventHandler";
import {doSetContextMenu, selectTableContextMenu} from "../../../slice/elementSlice";


export default function TableEditWindow({
                                            editingVenue,
                                            editingEvent,
                                            showWidth = false,
                                            showLength = false,
                                        }) {
    const [changed, setChanged] = useState({});

    const dispatch = useDispatch()

    const editingTables = useSelector(selectTableContextMenu);
    const selectedTablesRect = useSelector(selectSelectedTableRect);

    const zoom = useSelector(state => state.room.zoom);

    useEffect(() => {
        setChanged({})
    }, [editingTables]);

    const deleteSelectedTables = useCallback(() => {
        dispatch(doSetContextMenu(null));
        editingTables.forEach(t => dispatch(doDeleteTable(t.id)));
    }, [editingTables, dispatch]);

    const updateTableBackend = useCallback((changes) => {
        console.log('Actually calling the backend now', changes)
        Object.entries(changes).forEach(([id, values]) => {
            // console.log('updating table', id, values)
            dispatch(doUpdateTable({
                table_id: id,
                values: values
            }))
        })
    }, [dispatch]);

    const debouncedUpdateTable = useMemo(() => debounce(updateTableBackend, 800), [updateTableBackend]);

    useEffect(() => {
        if (Object.keys(changed).length === 0) return;

        debouncedUpdateTable(changed)
    }, [changed, debouncedUpdateTable]);

    const setTable = useCallback((id, values) => {
        setChanged(p => ({...p, [id]: {...p[id], ...values}}));
        dispatch(doSetTableLocally({table_id: id, values}))
    }, [debouncedUpdateTable, dispatch]);

    const closeEdit = useCallback(() => {
        dispatch(doSetEditingTable(null));
        dispatch(doSetContextMenu(null));
    }, [dispatch]);

    const similarValues = useMemo(() => {
        if (!editingTables) return {};
        const res = {
            name: null,
            type: null,
            active_seats: null,
            max_seating: null,
            width: null,
            length: null,
            rotation: null,
            seat_size: null,
        }
        for (const t of Object.values(editingTables)) {
            for (const key of Object.keys(res)) {
                if (res[key] === null) res[key] = t[key];
                if (res[key] !== t[key]) res[key] = '';
            }
        }
        return res
    }, [editingTables]);

    if (!editingTables || Object.keys(editingTables).length === 0 || !selectedTablesRect) return;

    console.log(editingTables)
    return (<>
        <MouseEventHandler
            mouseClick={closeEdit}
            mouseDown={closeEdit}
            style={{background: 'rgba(0, 0, 0, 0.1)'}}
        />

        <div className='table-edit-window'
             title={similarValues.name}
             style={{
                 left: selectedTablesRect.left + selectedTablesRect.width + 10,
                 top: selectedTablesRect.top,
                 transformOrigin: 'center left',
                 transform: `translateY(-50%) scale(${1 / zoom})`,
             }}
             onClose={closeEdit}>
            <h3>{similarValues.name}</h3>
            <p>Type: {similarValues.max_seating}-{similarValues.type}</p>
            <InputField
                title='Table name'
                value={similarValues.name}
                onChanged={n => editingTables.forEach(t => setTable(t.id, {name: n}))}
            />
            {editingEvent && <InputField
                title='Max seated for this event'
                value={similarValues.active_seats}
                onChanged={n => editingTables.forEach(t => setTable(t.id, {active_seats: n}))}
            />}
            {editingVenue && <InputField
                title='Max table seats'
                value={similarValues.max_seating}
                onChanged={n => editingTables.forEach(t => setTable(t.id, {max_seating: n}))}
            />}
            {showWidth && <InputField
                title='Width'
                value={similarValues.width}
                onChanged={n => editingTables.forEach(t => setTable(t.id, {width: n}))}
            />}
            {showLength && <InputField
                title='Length'
                value={similarValues.length}
                onChanged={n => editingTables.forEach(t => setTable(t.id, {length: n}))}
            />}

            <p className='rotation-handle-label'>Seat size</p>
            <label>{Math.floor(similarValues.seat_size*100)} cm</label>
            <input className='rotation-handle' type='range' min={20} max={70} value={Math.floor(similarValues.seat_size*100) || 45}
                   onChange={v => editingTables.forEach(t => setTable(t.id, {seat_size: v.target.value/100}))}/>


            <p className='rotation-handle-label'>Rotation</p>
            <label>{similarValues.rotation}</label>
            <input className='rotation-handle' type='range' min={-180} max={180} value={similarValues.rotation || 0}
                   onChange={v => editingTables.forEach(t => setTable(t.id, {rotation: v.target.value}))}/>

            <SimpleButton value={`Delete table${editingTables.length > 1 ? 's' : ''}`} className='red' big
                          onClick={deleteSelectedTables}/>

            <SimpleButton value='OK' onClick={closeEdit} big/>
        </div>
    </>)
}
