import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import SimpleButton from "../simple-button/SimpleButton";
import CenteredPopup from "../centered-popup/CenteredPopup";
import KFileDrop from "../k-file-drop/KFileDrop";
import './import-guest-list.css'
import {GuestAPI} from "../../api";
import {Link, useParams} from "react-router-dom";
import InputField from "../input-field/InputField";
import {doCreateGuest, doFetchEventGuests, selectCreatingGuestLoading} from "../../slice/guestSlice";

export default function ImportGuestList({linked_event_id}) {
    let {event_id: param_event_id} = useParams();
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);
    const [parsed, setParsed] = useState(null);

    const [headerMap, setHeaderMap] = useState({
        Name: 'name', Count: 'count', Company: 'company', Vegans: 'dietary', 'Dietary': 'dietary',
        'Wheelchair users': 'wheelchairs',
        'Seating Comment': 'comment'
    });

    const [importMethod, setImportMethod] = useState('template');
    const [guestData, setGuestData] = useState({
        name: '',
        company: '',
        dietary: '',
        wheelchairs: '',
        count: 1,
        comment: '',
    })
    const creatingGuestLoading = useSelector(selectCreatingGuestLoading)
    useEffect(() => {
        if (creatingGuestLoading) return;
        setGuestData({
            name: '',
            company: '',
            dietary: '',
            wheelchairs: '',
            count: 1,
            comment: '',
        })
    }, [creatingGuestLoading])

    const uploadedFile = useCallback((name, data) => {
        GuestAPI.parseExcelGuestList(name, data).then(setParsed)
    }, []);

    const event_id = useMemo(() => {
        if (linked_event_id) return linked_event_id
        return param_event_id
    }, [param_event_id, linked_event_id])

    const acceptedGuestList = useCallback(() => {
        if (!event_id) return;
        GuestAPI.createGuests(event_id, parsed.guests, parsed.headers, headerMap).then(_ => {
            setParsed(null)
            setOpen(false)
            dispatch(doFetchEventGuests(event_id))
        })
    }, [dispatch, headerMap, parsed, event_id]);

    const selectedHeaderMapping = useCallback((excelHeader, targetHeader) => {
        setHeaderMap(prev => {
            const f = {
                ...prev,
            }
            Object.keys(f).forEach(excelHeader => {
                if (f[excelHeader] === targetHeader) {
                    delete f[excelHeader]
                }
            })
            f[excelHeader] = targetHeader

            return f
        })
    }, []);

    if (!open) {
        return <SimpleButton className='import-guests-button' value='Importer gæster' onClick={_ => setOpen(true)}/>
    }

    return (<CenteredPopup className={'import-guest-list'} showPopup={true} closePopup={setOpen}>
        <div className='guest-header'>
            <h1>Importerer gæsteliste</h1>
            {importMethod === 'template' && !parsed && <SimpleButton value='Download gæste template' onClick={_ => {
                window.open(`${(process.env.REACT_APP_API_URL || "http:///localhost:5000")}/guest/download-guest-template`, '_blank')
            }}/>}
            {importMethod === 'template' && parsed && <>
                <SimpleButton value='Clear' onClick={_ => {
                    setParsed(null)
                }}/>
                <SimpleButton disabled={parsed.required_headers.some(rh => Object.values(headerMap).indexOf(rh) === -1)}
                              value='Accept import' onClick={acceptedGuestList}/>
            </>}
        </div>
        <div className='import-method-selection'>
            <p onClick={_ => setImportMethod('template')}
               className={importMethod === 'template' && 'selected'}>Template</p>
            <p onClick={_ => setImportMethod('manual')} className={importMethod === 'manual' && 'selected'}>Manual</p>
        </div>

        {importMethod === 'manual' && <div className='manual-input'>
            <InputField
                value={guestData.name} onChanged={v => setGuestData(p => ({...p, name: v}))}
                title='Navn*'/>
            <InputField
                value={guestData.company} onChanged={v => setGuestData(p => ({...p, company: v}))}
                title='Firma*'/>
            <InputField
                value={guestData.count}
                onChanged={v => setGuestData(p => ({...p, count: v}))}
                title='Antal*'/>
            <InputField
                value={guestData.dietary} onChanged={v => setGuestData(p => ({...p, dietary: v}))}
                title='Diætære restriktioner'/>
            <InputField
                value={guestData.wheelchairs}
                onChanged={v => setGuestData(p => ({...p, wheelchairs: v}))}
                title='Dårligt gående?'/>
            <InputField
                value={guestData.comment}
                onChanged={v => setGuestData(p => ({...p, comment: v}))}
                title='Seating kommentar'/>
            <SimpleButton
                disabled={
                    !guestData.name.replace(/\s/, '') ||
                    !guestData.company ||
                    !guestData.count}
                value='OPRET' loading={creatingGuestLoading}
                onClick={_ => {
                    dispatch(doCreateGuest({event_id, ...guestData}))
                }}/>
        </div>}

        {importMethod === 'template' && !parsed && <>
            <p>Upload en gæsteliste</p>
            <KFileDrop didSelectFile={uploadedFile} asPopup={false} displayFiles={false}
                       accept={'.xlsx, .xls, .numbers'}/>
        </>}
        {importMethod === 'template' && parsed && <>
            <p>Vælg venligst hvilke kollonner stemmer overens med hvilke værdier. Sådan så 'name' er valgt for kollonnen
                med gæstens navn osv</p>
            <table>
                <thead>
                <tr>
                    {parsed.headers.map(excelHeader => <th key={excelHeader}>
                        <InputField
                            onlySelectableOptions
                            options={parsed.required_headers}
                            value={headerMap[excelHeader]}
                            onChanged={v => selectedHeaderMapping(excelHeader, v)}
                        />
                    </th>)}
                </tr>
                <tr>
                    {parsed.headers.map(excelHeader => <th key={excelHeader}>{excelHeader}</th>)}
                </tr>
                </thead>
                <tbody>
                {parsed.guests.map(guest => <tr>
                    {guest.map((v, idx) => <td key={idx}>{v}</td>)}
                </tr>)}
                </tbody>
            </table>
        </>}
    </CenteredPopup>)
}
