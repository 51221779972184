import React, {useCallback, useEffect, useState} from 'react'
import {Outlet} from "react-router-dom";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import './venue-page.css'
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import CreateNewEvent from "../../../component/create-new-event/CreateNewEvent";

export default function VenuePage() {
    // new event creation
    return (<div className={'venue-page'}>
        <Outlet />
    </div>)
}
