import React from 'react'
import './simple-link.css'
import {Link} from "react-router-dom";

export default function SimpleLink({className, value, path, disabled=false, loading=false, big=false}) {
    return <Link
        to={path}
        className={`simple-link ${className} ${disabled ? 'disabled' : ''} ${loading && 'loading'} ${big ? 'big' : ''}`}
        disabled={disabled}
    >{value}</Link>
}
