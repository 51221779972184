import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    doFetchVenues,
    selectVenueLoading,
    selectVenues
} from "../../slice/venueSlice";
import {Link, Outlet, useNavigate, useParams} from "react-router-dom";
import {doFetchVenueEvents, selectVenueEvents} from "../../slice/eventSlice";
import SimpleButton from "../../component/simple-button/SimpleButton";
import {doGetUser, selectUser} from "../../slice/userSlice";
import './venue-page-wrapper.css'

export default function VenuePageWrapper() {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const menuItems = useMemo(() => [{
        title: 'Events',
        link: 'events'
    }, {
        title: 'Lokaler',
        link: 'lokaler'
    }, {
        title: 'Borde & Elementer',
        link: 'borde-elementer'
    }]);

    const url = window.location.href;
    const currentUrlTab = url.split('/').pop();

    let {event_id, venue_id} = useParams();
    event_id = parseInt(event_id)
    venue_id = parseInt(venue_id)

    const user = useSelector(selectUser)
    const venues = useSelector(selectVenues)
    const venueLoading = useSelector(selectVenueLoading)
    const events = useSelector(selectVenueEvents(venue_id));

    const selectedVenue = useMemo(() => {
        if (!venues || !venue_id) return null;
        return venues.find(v => v.id === venue_id)
    }, [venue_id, venues]);

    useEffect(() => {
        dispatch(doGetUser())
    }, [dispatch]);

    useEffect(() => {
        if (venues !== null) return;
        if (venueLoading) return;
        console.log('Fetch venues from admin page')
        dispatch(doFetchVenues())
    }, [dispatch, venues, venueLoading]);

    useEffect(() => {
        console.log('Check if we should navigate', {
            venues,
            venue_id
        })
        if (!venues || (venues.length === 0 || !!venue_id)) return;
        console.log('Navigate to first venue', venues[0].id)
        navigate(`/app/venue/${venues[0].id}/events`)
    }, [venues, venue_id]);

    useEffect(() => {
        if (!venue_id) return;
        dispatch(doFetchVenueEvents(venue_id))
    }, [venue_id]);

    if (!venues || !selectedVenue) return <div>Loading...</div>

    return (<div className='venue-page-wrapper'>
        <div className='left-menu'>
            <div className={`header-wrapper ${venue_id === selectedVenue.id ? 'selected' : ''}`}
                 onClick={_ => {
                     navigate(`/app/venue/${selectedVenue.id}/events`)
                 }}>
                <h2>{selectedVenue.name}</h2>
                <h5>{selectedVenue.address}</h5>
            </div>
            {menuItems.map(mi =>
                <Link to={`/app/venue/${selectedVenue.id}/${mi.link}`}
                      key={mi.link} className={`menu-item ${mi.link === currentUrlTab ? 'selected' : ''}`}>
                    {mi.title}
                </Link>)}

            <div className='grower'/>
            {selectedVenue && <select defaultValue={selectedVenue.id} className='venue-select' onChange={e => {
                navigate(`/app/venue/${e.target.value}/events`)
            }}>
                {venues.map(venue => <option key={venue.id} value={venue.id}>{venue.name}</option>)}
            </select>}
            {user && user.role === 'developer' && <SimpleButton value='admin' onClick={_ => navigate(('/admin'))}/>}
            <SimpleButton value='Log ud' onClick={_ => navigate('/logout')}/>
        </div>
        <div className={`scrollable`}>
            <Outlet/>
        </div>
    </div>)
}
