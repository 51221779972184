import React, {useCallback, useMemo} from 'react';
import {
    doDeleteElement, doSetContextMenu,
    doSetElementPropertyLocally,
    elementDraggedOverRoom,
    elementDroppedOnRoom
} from "../../../slice/elementSlice";
import {useDispatch} from "react-redux";
import useElementBackend from "./useElementBackend";
import SimpleButton from "../../simple-button/SimpleButton";


export const elementTypes = {
    'square': {
        type: 'square',
        properties: {
            text: ",..",
            fontSize: 18,
            width: 3,
            length: 3,
            backgroundColor: 'white',
            borderColor: 'black',
            position: {
                x: 50,
                y: 50
            },
            rotation: 0,
            opacity: 1,
        },
        editable: {
            text: 'text',
            fontSize: 'number',
            width: 'float',
            length: 'float',
            backgroundColor: 'color',
            borderColor: 'color',
            rotation: 'rotation',
            opacity: 'float',
        },
        setPreviewPosition: (x, y) => ({
            position: {
                x: x,
                y: y
            }
        }),
        isWithinBounds: (topLeft, bottomRight, element) => {
            const {position, width, length} = element.properties;
            let w = width || 0;
            let l = length || 0;
            const pos = position;
            return (
                (pos.x > topLeft.x && pos.x < bottomRight.x && pos.y > topLeft.y && pos.y < bottomRight.y) ||
                (pos.x + w > topLeft.x && pos.x + w < bottomRight.x && pos.y > topLeft.y && pos.y < bottomRight.y) ||
                (pos.x > topLeft.x && pos.x < bottomRight.x && pos.y + l > topLeft.y && pos.y + l < bottomRight.y) ||
                (pos.x + w > topLeft.x && pos.x + w < bottomRight.x && pos.y + l > topLeft.y && pos.y + l < bottomRight.y)
            )
        },
        getBoundingRect(element, pixelPrMeter) {
            const {position, width, length, rotation} = element.properties;
            const {x, y} = position;
            const o = rotation * Math.PI / 180;
            const h = Math.abs((width*pixelPrMeter) * Math.sin(o)) + Math.abs((length*pixelPrMeter) * Math.cos(o))
            const w = Math.abs((width*pixelPrMeter) * Math.cos(o)) + Math.abs((length*pixelPrMeter) * Math.sin(o))
            return {
                left: position.x - (w) / 2,
                top: position.y - (h) / 2,
                width: w,
                height: h,
            }
        }
    },
    oval: {
        type: 'oval',
        properties: {
            text: "...",
            fontSize: 18,
            radius: 3,
            backgroundColor: 'white',
            borderColor: 'black',
            position: {
                x: 50,
                y: 50
            }
        },
        editable: {
            text: 'text',
            fontSize: 'number',
            radius: 'number',
            backgroundColor: 'color',
            borderColor: 'color',
        },
        setPreviewPosition: (x, y) => ({
            position: {
                x: x,
                y: y
            }
        }),
        isWithinBounds: (topLeft, bottomRight, element) => {
            const {position, radius} = element.properties;
            const pos = position;
            return (
                (pos.x - radius > topLeft.x && pos.x + radius < bottomRight.x && pos.y - radius > topLeft.y && pos.y + radius < bottomRight.y)
            )
        },
        getBoundingRect(element, pixelPrMeter) {
            const {position, radius} = element.properties;
            return {
                left: position.x - radius * pixelPrMeter,
                top: position.y - radius * pixelPrMeter,
                width: radius * 2 * pixelPrMeter,
                height: radius * 2 * pixelPrMeter,
            }
        }
    },
    line: {
        type: 'line',
        properties: {
            radius: 3,
            pointColor: 'white',
            borderColor: 'black',
            lineColor: 'black',
            displayDistance: true,
            lineWidth: 1,
            position_start: {
                x: 50,
                y: 50
            },
            position_end: {
                x: 50,
                y: 60
            }
        },
        editable: {
            radius: 'number',
            pointColor: 'color',
            borderColor: 'color',
            lineColor: 'color',
            displayDistance: 'checkbox',
            lineWidth: 'number',
        },
        setPreviewPosition: (x, y) => ({
            position_start: {
                x: x,
                y: y - 50
            },
            position_end: {
                x: x,
                y: y + 50
            }
        }),
        isWithinBounds: (topLeft, bottomRight, element) => {
            const {position_start, position_end, radius} = element.properties;
            const pos_start = position_start;
            const pos_end = position_end;
            return (
                (pos_start.x > topLeft.x && pos_start.x < bottomRight.x && pos_start.y > topLeft.y && pos_start.y < bottomRight.y) ||
                (pos_end.x > topLeft.x && pos_end.x < bottomRight.x && pos_end.y > topLeft.y && pos_end.y < bottomRight.y)
            )
        },
        getBoundingRect(element, pixelPrMeter) {
            const {position_start, position_end, radius} = element.properties;
            return {
                left: Math.min(position_start.x, position_end.x) - radius,
                top: Math.min(position_start.y, position_end.y) - radius,
                width: Math.abs(position_start.x - position_end.x) + radius * 2,
                height: Math.abs(position_start.y - position_end.y) + radius * 2,
            }
        },
    },
};

export default function useElements() {
    const dispatch = useDispatch();
    const {updateElementBackend} = useElementBackend()

    const renderElementContextMenu = useCallback(elements => {
        let editable = {};
        let editableTypes = {};
        elements.forEach(element => {
            Object.entries(elementTypes[element.type].editable).forEach(([key, type]) => {
                if (!editable[key]) {
                    editable[key] = 1;
                    editableTypes[key] = type
                } else {
                    editable[key] += 1;
                }
            })
        })
        editable = Object.keys(Object.fromEntries(Object.entries(editable).filter(([key, value]) => value === elements.length)))

        const values = {};
        elements.forEach(element => {
            editable.forEach(key => {
                if (values[key] === undefined) {
                    values[key] = element.properties[key];
                } else if (element.properties[key] !== values[key]) {
                    values[key] = null;
                } else {
                    values[key] = element.properties[key];
                }
            })
        })

        return <>
            {editable.map((key) => {
                const inputType = editableTypes[key];
                return <div key={key}>
                    <label htmlFor={key}>{key}</label>
                    <br/>
                    <input
                        id={key}
                        type={inputType}
                        value={values[key] || ''}
                        checked={values[key] || false}
                        onChange={t => {
                            elements.forEach(element => {
                                dispatch(doSetElementPropertyLocally({
                                    element_id: element.id,
                                    key,
                                    value: inputType === 'checkbox' ? t.target.checked : t.target.value,
                                }))
                            })
                            updateElementBackend()
                        }}
                    />
                </div>
            })}
            <SimpleButton red value='DELETE' onClick={() => {
                elements.forEach(element => {
                    dispatch(doDeleteElement(element.id))
                })
                dispatch(doSetContextMenu(null))
            }}/>
        </>
    }, [dispatch]);

    return {
        previewElement: (element, {x, y}) => {
            dispatch(elementDraggedOverRoom({
                ...element,
                properties: {
                    ...element.properties,
                    ...(elementTypes[element.type].setPreviewPosition(x, y))
                }
            }))
        },
        createElement: (element, {x, y}) => {
            dispatch(elementDroppedOnRoom({
                ...element,
                properties: {
                    ...element.properties,
                    ...(elementTypes[element.type].setPreviewPosition(x, y))
                },
            }))
        },
        renderElementContextMenu,
    }
}